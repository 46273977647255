import React, { useEffect, } from 'react';
import { omitBy, pick, isUndefined, get, isEmpty, keyBy, omit, sortBy } from 'lodash';
import copy from 'copy-to-clipboard';
import numeral from 'numeral';
import { Button, Badge, Input, } from 'reactstrap';
import { format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import firebase from '../../firebase';
import { getCollectionData } from '../../shared/firebase';
import { canUpdateOrder } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useCreateActivity from '../hooks/useCreateActivity';
import { EditButton as EditButtonV9 } from '../v9/EditButton';
import EditButton from '../EditButton';
import ModelFormModal from '../modals/ModelFormModal';
import Activity from '../Activity';
import { areaFromPostalCode } from '../../shared/models/setting';
import { prefectures } from '../../shared/config';
import {
  fields,
  payerFields,
  paymentMethods,
  paymentMethod,
  totalPaymentAmount,
  contactorFields,
  activityTypes,
  referrerFields,
  computeReferralFee,
} from '../../shared/models/order';
import { fieldDisplayValue } from '../../shared/util';
import TenantLink from '../TenantLink';
import useTenant from '../hooks/useTenant';
import ModalButton from '../ModalButton';
import ProgressButton from '../ProgressButton';
import QrCode from '../QrCode';
import { useOrderDocument, orderRef } from '../../models/order';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';
import { useReferrerCollection, tenantReferrersByKeyGroupQuery } from '../../models/referrer';
import { useAgentDocument, agentRef } from '../../models/agent';
import { useAgentShopDocument, agentShopRef } from '../../models/agentShop';
import { useQrUrlDocument, qrUrlRef } from '../../models/qrUrl';
import { useCouponDocument, couponRef } from '../../models/coupon';
import { useProductDocumentsOnce, productRef } from '../../models/product';
import { useActivityCollection, tenantActivitiesByPayloadQuery } from '../../models/activity';

const db = firebase.firestore();

export default AdminPage(function AdminOrder(props) {
  const {
    user,
    match: {
      params: { orderId },
    },
  } = props;
  const tenant = useTenant();
  const createActivity = useCreateActivity();
  const { firebaseUser } = useFirebaseUser();
  const { data: order } = useOrderDocument(orderRef(orderId));
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const area =
    order && areaSetting && areaFromPostalCode(order.contactorPostalCode || order.destinationPostalCode, areaSetting);
  const { data: [referrer] = [] } = useReferrerCollection(
    tenantReferrersByKeyGroupQuery(tenant?.id, order?.referrerKey)
  );
  const agentId = order?.wholesaleAgentId || get(referrer, 'ref.parent.parent.parent.parent.id');
  const agentShopId = order?.wholesaleAgentShopId || get(referrer, 'ref.parent.parent.id');
  const { data: agent } = useAgentDocument(agentRef(agentId));
  const { data: agentShop } = useAgentShopDocument(agentShopRef(agentRef(agentId), agentShopId));
  const { data: qrUrl } = useQrUrlDocument(qrUrlRef(order?.qrUrlId));
  const allCoupons = [order?.coupon, ...(order?.otherCoupons || [])].filter(_ => _);
  const orderProductRefs = order?.orderItems.map(({ productId }) => productRef(productId));
  const { data: orderProducts } = useProductDocumentsOnce(orderProductRefs);
  const orderProductsById = keyBy(orderProducts, 'id');
  const orderItemsWithProduct = order?.orderItems.map((orderItem) => {
    const { productId } = orderItem;
    const product = orderProductsById[productId];
    return { ...orderItem, product };
  });
  const { data: activities = [] } = useActivityCollection(
    tenantActivitiesByPayloadQuery(tenant?.id, 'orderId', orderId)
  );
  const handleEditedContactor = async () => {
    await createActivity('editContactorOfOrder', user, { orderId: order?.id });
  };
  const onClickCancel = async () => {
    if (
      !window.confirm(
        'CAM MACS側でキャンセルすれば自動的にこちらもキャンセル状態になるはずですが、本当にキャンセルしますか？'
      )
    )
      return;

    try {
      await order.ref.update({
        updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
        cancelledAt: new Date(),
        cammacsStatus: 'cancelled',
      });
      toast.success('キャンセルしました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onClickIgnoreHasSimilarOrders = async () => {
    if (!window.confirm('同一の注文がありますが、無視して通常の注文として扱いますか？')) return;

    try {
      await order.ref.update({
        updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
        shouldStopBecauseHaveSimilarOrders: false,
      });
      toast.success('同一の注文チェックを無視しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onClickIgnoreHasMultiBodyOrders = async () => {
    if (!window.confirm('本体複数の注文ですが、無視して通常の注文として扱いますか？')) return;

    try {
      await order.ref.update({
        updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
        shouldStopBecauseHaveMultiBodyOrders: false,
      });
      toast.success('本体複数注文チェックを無視しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    order != null && (
      <div className="admin-inquiry container">
        <div className="p-4 bg-white my-4">
          <div className="d-flex justify-content-center mb-3">
            <h4>注文詳細</h4>
          </div>
          <div className="d-flex justify-content-end mb-3 gap-2">
            <ModalButton title="オーナー移転QR" color='secondary' content={_ => <OwnerMigrationModalContent {...{ order, }} />}>
              オーナー移転QR
            </ModalButton>
            <Button
              color='secondary'
              tag={TenantLink}
              to={`/admin/orders/${order.id}/receipt`}
              target='_blank'
            >
              領収書
            </Button>
            <Button
              color='danger'
              onClick={onClickCancel}
              disabled={order.cancelledAt != null || !canUpdateOrder(user)}
            >
              キャンセル
            </Button>
            {
              order.shouldStopBecauseHaveSimilarOrders && (
                <ProgressButton process={onClickIgnoreHasSimilarOrders}>
                  同一の注文チェックを無視する
                </ProgressButton>
              )
            }
            {
              order.shouldStopBecauseHaveMultiBodyOrders && (
                <ProgressButton process={onClickIgnoreHasMultiBodyOrders}>
                  本体複数注文チェックを無視する
                </ProgressButton>
              )
            }
            <EditButton
              label="リファラ編集"
              itemRef={order.ref}
              FormModal={ModelFormModal}
              formProps={{
                title: 'リファラ編集',
                values: order,
                fields: referrerFields,
              }}
              validateOnSubmit={async _ => isEmpty(_.referrerKey) || (await getCollectionData(db.collectionGroup('referrers').where('key', '==', _.referrerKey).limit(1))).length > 0 || window.alert('リファラが存在しません')}
              disabled={paymentMethod(order) !== 'credit'}
              beforeSubmit={_ => ({
                ..._,
                referralFee: isEmpty(_.referrerKey) ? 0 : computeReferralFee(order.orderItems, orderProductsById, allCoupons)
              })}
            />
            <EditButtonV9
              label="利用者情報変更"
              icon={null}
              itemRef={orderRef(order.id)}
              FormModal={ModelFormModal}
              formProps={{
                title: '利用者情報変更',
                values: order,
                fields: contactorFields,
                submitLabel: '変更する',
              }}
              onFinish={handleEditedContactor}
            />
          </div>
          <div>
            <table className="table table-bordered">
              <tbody className="thead-light">
                <tr>
                  <th>注文ID</th>
                  <td>{order.id}</td>
                </tr>
                <tr>
                  <th>CAMMACSステータス</th>
                  <td>
                    {order.cammacsStatus}
                    {order.forShop && isEmpty(order.referrerKey) && <Badge color="warning" className='ml-1'>要リファラ</Badge>}
                    {order.shouldStopBecauseHaveSimilarOrders && <Badge color="warning" className='ml-1'>同一の注文あり</Badge>}
                    {order.shouldStopBecauseHaveMultiBodyOrders && <Badge color="warning" className='ml-1'>本体複数注文</Badge>}
                    {order.isWish && <span className="ml-1 badge badge-info">おねだり</span>}
                  </td>
                </tr>
                <tr>
                  <th>予約注文</th>
                  <td>{order.isPreOrder && '予約注文'}</td>
                </tr>
                <tr>
                  <th>エリア</th>
                  <td>
                    {area && (
                      <span>
                        <span>{prefectures[area.prefecture]}</span>
                        <span>{area.city}</span>
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>担当</th>
                  <td>{area?.user.displayName}</td>
                </tr>
                <tr>
                  <th>決済方法</th>
                  <td>
                    <div>{paymentMethods[paymentMethod(order)].label}</div>
                    {order.isWish && (order.charge ?? order.paymentIntent) == null && (<span className="badge badge-secondary">未決済</span>)}
                  </td>
                </tr>
                <tr>
                  <th>代理店</th>
                  <td>{agent?.name}</td>
                </tr>
                <tr>
                  <th>店舗</th>
                  <td>{agentShop?.name}</td>
                </tr>
                <tr>
                  <th>リファラ</th>
                  <td>{referrer?.name}</td>
                </tr>
                <tr>
                  <th>紹介QRコード遷移先</th>
                  <td>{qrUrl?.name}</td>
                </tr>
                <tr>
                  <th>直前URL</th>
                  <td>
                    {!isEmpty(order.referrerUrl) && (
                      <a href={order.referrerUrl} target="_blank">
                        <span className="text-break">{order.referrerUrl}</span>
                      </a>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>どちらでお知りになりましたか？</th>
                  <td>{order.route}</td>
                </tr>
                <tr>
                  <th>優待</th>
                  <td>
                    {
                      allCoupons.map((coupon) => {
                        return (
                          <div key={coupon.id} className="card p-1 mb-1">
                            {coupon.name}
                          </div>
                        );
                      })
                    }
                  </td>
                </tr>
                <tr>
                  <th>アカウント</th>
                  <td>
                    {order.createdBy && (
                      <TenantLink to={`/admin/users/${order.createdBy.uid}`}>{order.createdBy.displayName}</TenantLink>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>商品</th>
                  <td>
                    {orderItemsWithProduct?.map((orderItem) => {
                      const { product, quantity } = orderItem;
                      return (
                        product != null && (
                          <div key={product.code}>
                            <span>{product.code}</span>
                            <span className="ml-2">{product.name}</span>
                            <span className="ml-2">{numeral(quantity).format('0,0')}個</span>
                          </div>
                        )
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <th>合計金額(税込)</th>
                  <td>{numeral(order.amount).format('0,0')}</td>
                </tr>
                <tr>
                  <th>割引額(税込)</th>
                  <td>{numeral(order.discountAmount).format('0,0')}</td>
                </tr>
                <tr>
                  <th>送料(税込)</th>
                  <td>{numeral(order.shipmentFee).format('0,0')}</td>
                </tr>
                <tr>
                  <th>支払合計(税込)</th>
                  <td>{numeral(totalPaymentAmount(order)).format('0,0')}</td>
                </tr>
                <tr>
                  <th>入金日</th>
                  <td>{order.receivedOn && formatDate(order.receivedOn.toDate(), 'yyyy/MM/dd')}</td>
                </tr>
                <tr>
                  <th>入金額</th>
                  <td>{order.receivedAmount && numeral(order.receivedAmount).format('0,0')}</td>
                </tr>
                <tr>
                  <th>決済手数料</th>
                  <td>{order.stripeFee != null && numeral(order.stripeFee).format('0,0')}</td>
                </tr>
                <tr>
                  <th>Stripeへの返金日</th>
                  <td>{order.refundedOn && formatDate(order.refundedOn.toDate(), 'yyyy/MM/dd')}</td>
                </tr>
                <tr>
                  <th>Stripeへの返金額</th>
                  <td>{order.refundAmount && numeral(order.refundAmount).format('0,0')}</td>
                </tr>
                <tr>
                  <th>注文日時</th>
                  <td>{formatDate(order.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                </tr>
                <tr>
                  <th>キャンセル日時</th>
                  <td>{order.cancelledAt && formatDate(order.cancelledAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                </tr>
                <tr>
                  <th>出荷日</th>
                  <td>{order.shippedDate}</td>
                </tr>
                {Object.entries(omit({ ...fields(), ...payerFields(), }, ['route'])).map(([fieldName, fieldSettings]) => {
                  const prefix = fieldName.startsWith('destination')
                    ? '配送先'
                    : fieldName.startsWith('contactor')
                    ? '利用者情報'
                    : fieldName.startsWith('payer')
                    ? '決済者'
                    : '';
                  return (
                    <tr key={fieldName}>
                      <th>
                        {prefix}
                        {fieldSettings.label}
                      </th>
                      <td>{fieldDisplayValue(order[fieldName], fieldSettings)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <th>おねだり決済日時</th>
                  <td>{order.wishPaidAt && formatDate(order.wishPaidAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr className="my-5" />
          <div>
            <h5>アクティビティ</h5>
            <div className="mt-4">
              {activities.length > 0 ? (
                <div>
                  <div className="d-flex flex-column gap-1">
                    {sortBy(activities, (_) => _.createdAt.toDate()).map((_) => (
                      <Activity activity={_} activityTypes={activityTypes} />
                    ))}
                  </div>
                </div>
              ) : (
                <div>アクティビティはまだありません</div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
});

function OwnerMigrationModalContent(props) {
  const { order, } = props;
  const url = `${window.origin}/orderOwnerMigrate?token=${order.ownerMigrationToken}`;
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };
  useEffect(() => {
    if(order != null && order.ownerMigrationToken == null) {
      order.ref.update({ ownerMigrationToken: uuid() });
    }
  }, [order]);

  return (
    <div>
      <div className="d-flex justify-content-center" title={url}>
        <QrCode url={url} />
      </div>
      <div className="mt-4">
        <div className="d-flex">
          <Input className="flex-grow-1 mr-2" readOnly defaultValue={url} />
          <Button color="primary" onClick={onClickCopy}>
            <span className="fas fa-copy" />
          </Button>
        </div>
      </div>
    </div>
  );
}
