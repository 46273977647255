import React from 'react';
import { pick, isEmpty, keyBy, differenceWith, isEqual, isArray, isObject } from 'lodash';
import { toast } from 'react-toastify';
import ModalButton from './ModalButton';
import SurveyAnswerFormModal from './modals/SurveyAnswerFormModal';
import firebase from '../firebase';
import { addActivity } from 'src/models/activity';

const { keys, entries } = Object;
const storageRef = firebase.storage().ref();
const db = firebase.firestore();
const activitiesRef = db.collection('activities');

export default function SurveyAnswerEditButton(props) {
  const { survey, questions, activities, surveyAnswer, user } = props;
  const { id: surveyAnswerId, answers, createdAt, ref, tenantId } = surveyAnswer;
  const questionsById = keyBy(questions, 'id');
  const answersById = answers;
  const onSubmit = async ({ answers: _answers }) => {
    try {
      const answers = await entries(_answers).reduce(async (x, [k, v]) => {
        const prevs = await x;

        const question = questionsById[k];
        // TODO: ファイル削除
        if (false && question?.type === 'imageFile') {
          const files = answersById[k];
          await Promise.all(
            files
              .filter((_) => !(v || []).map(({ name }) => name).includes(_.name))
              .map(async (file) => {
                const fileRef = storageRef.child(`surveyAnswers/${ref.id}/${k}/${file.name}`);
                return fileRef.delete();
              })
          );
        }
        return {
          ...prevs,
          [k]:
            question?.type === 'imageFile' && !isEmpty(v)
              ? await Promise.all(
                  v.map(async (file) => {
                    if(file.url) return file;

                    const fileRef = storageRef.child(
                      `surveyAnswers/${ref.id}/${k}/${new Date().toISOString()}/${file.name}`
                    );
                    !file.url && (await fileRef.put(file, { contentType: file.type }));
                    return {
                      ...pick(file, ['name', 'type']),
                      url: await fileRef.getDownloadURL(),
                    };
                  })
                )
              : (v ?? null),
        };
      }, Promise.resolve({}));

      await ref.update({ answers, updatedAt: new Date() });

      const diff = differenceWith(Object.entries(answersById), Object.entries(_answers), isEqual);
      const note = diff.map(([key, value]) => {
        const after = _answers[key];
        if (isArray(after))
          return `${value.map((_) => _?.name || _).join(', ')} > ${after.map((_) => _?.name || _).join(', ')}`;
        if (isObject(after)) return `${Object.keys(value)} > ${Object.keys(after)}`;
        if(value == null && after == null) return;
        return `${value} > ${after}`;
      }).filter(_ => _);

      await addActivity({
        type: 'updateSurveyAnswer',
        payload: { surveyAnswer, uid: surveyAnswer.createdBy?.uid, note },
        tenantId,
        createdBy: user,
      });
    } catch (error) {
      console.error(error);
      toast.error('失敗しました');
    }
  };

  return (
    <ModalButton
      Modal={SurveyAnswerFormModal}
      modalProps={{
        onSubmit,
        survey,
        questions,
        answers,
        activities,
        surveyAnswer,
        user,
      }}
      label="アンケート回答編集"
    />
  );
}
