import React, { Fragment, } from 'react';
import { Button, Form, FormGroup, Label, Alert } from 'reactstrap';
import { keyBy, uniqBy, groupBy, } from 'lodash';
import { format as formatDate, } from 'date-fns';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import sanitizeHtml from 'sanitize-html';
import { useToggle, useAsync, } from 'react-use';

import firebase, { functions } from '../../firebase';
import { activateRichTextHtml } from '../../util';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import EntryflowSection from '../EntryflowSection';
import LectureTypeDisplay from '../LectureTypeDisplay';
import LectureLevelDisplay from '../LectureLevelDisplay';
import LectureAgeDisplay from '../LectureAgeDisplay';
import AppButton from '../AppButton';
import AppCardElement from '../AppCardElement';
import RichTextContent from '../RichTextContent';
import env from '../../env';
import StripeForm from './StripeForm';
import { stripePromise } from '../../stripe';

const createPaymentIntent = functions.httpsCallable('createPaymentIntent');

const { entries, keys } = Object;

const fields = ({ hasCaution }) => ({
  hasAccepted: {
    type: 'boolean',
    label: '注意事項に同意する',
    validations: {
      required: v => v === true,
    },
    hidden: _ => !hasCaution
  },
});

export default function EntryConfirmForm (props) {
  const { version, translate, event, lectures = [], questions = [], values, onClickBack, surveys = [], lectureTypesById, isTestEntry } = props;
  const surveysById = keyBy(surveys, 'id');
  const { frames, answers } = values;
  const framesGroupedByLectureId = groupBy(frames, 'lectureId');
  const lecturesById = keyBy(lectures, 'id');
  const { hasCaution, cautionText } = event;
  const statedFields = useFormState({}, fields({ hasCaution }));
  const relatedLectures = frames.map(_ => _.lectureId).map(_ => lecturesById[_]);
  const questionsById = keyBy(questions, 'id');
  const visibleQuestions = uniqBy(relatedLectures.map(_ => [...(_.questions || []), ...((_.surveyIds ?? []).flatMap(_ => surveysById[_]?.questionRows ?? []).map(_ => _.questionId) || [])]).flat().map(_ => questionsById[_] || {}), 'id')
    .filter(_ => _.id in answers);
  const isUnsubmittable = (
    Object.values(statedFields).some(_ => !_.isValid)
  );
  // TODO: 将来的にはユーザーの場合のみ無料にする
  const amount = frames.map(_ => lecturesById[_.lectureId] || {}).filter(_ => !_.isUserFree).map(_ => _.price).reduce((x, y) => x + y, 0);
  const surveyIds = relatedLectures.flatMap(_ => _.surveyIds ?? []);
  const [isSubmitting, toggleSubmitting] = useToggle();
  const { value: paymentIntent, loading } = useAsync(async () => {
    return amount > 0 && (await createPaymentIntent({ amount, })).data;
  }, [amount, version]);
  const onSubmit = async (stripe, elements) => {
    if(isUnsubmittable || isSubmitting) return;

    toggleSubmitting(true);
    try {
      await props.onSubmit(stripe, elements, { stripePaymentIntent: paymentIntent, frames, answers, amount, surveyIds });
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };

  return (
    <div>
      {translate(<EntryflowSection activeIndex={2} />)}
      {
        (!(amount > 0) || paymentIntent?.client_secret != null) && !loading && (
          <Elements stripe={stripePromise} options={{ clientSecret: paymentIntent?.client_secret, }}>
            <StripeForm onSubmit={onSubmit}>
              <section className="container mt-5">
                <div>
                  {translate(<div>
                    <div className="card border-danger text-danger p-3 text-center font-weight-bold">
                      予約はまだ完了していません
                    </div>
                    <h4 className="mt-4 h5 text-center font-weight-bold">
                      申込内容をご確認ください
                    </h4>
                    <div className="mt-4">
                      {
                        entries(framesGroupedByLectureId).map(([lectureId, framesInLecture]) => {
                          const lecture = lecturesById[lectureId] || {};
                          const { frames: lectureFrames } = lecture;
                          return (
                            <div key={lectureId} className="card p-3 mb-2">
                              <div>
                                  {
                                    framesInLecture.map((frame) => {
                                      const { name, birthday, frameIndex } = frame;
                                      const { startAt, endAt, lectureType, } = lectureFrames[frameIndex];
                                      const index = frames.indexOf(frame);
                                      return (
                                        <div key={index} className="mt-2">
                                          <div className="large">
                                            {formatDate(lecture.date.toDate(), 'yyyy/MM/dd')} {formatDate(startAt.toDate(), 'HH:mm')} {<span>{lectureTypesById[lectureType]?.name}</span>}
                                          </div>
                                          <div className="mt-2 d-flex align-items-center">
                                            <LectureTypeDisplay event={event} {...lecture} />
                                            <div className="ml-3 text-grey">
                                            {/* TODO: 将来的にはユーザーの場合のみ無料にする */}
                                              {lecture.isUserFree ? '0' : numeral(lecture.price).format('0,0')} 円(税込)
                                            </div>
                                          </div>
                                          <div className="mt-3 d-flex">
                                            <LectureLevelDisplay event={event} className="flex-fill" {...lecture} />
                                            <LectureAgeDisplay event={event} className="ml-3 flex-fill" {...lecture} />
                                          </div>
                                          <div className="mt-2">
                                            <table className="table table-borderless table-sm">
                                              <tbody>
                                                <tr>
                                                  <td>参加者名</td>
                                                  <td className="font-weight-bold">{name}</td>
                                                </tr>
                                                <tr>
                                                  <td>生年月日</td>
                                                  <td className="font-weight-bold">{formatDate(birthday, 'yyyy / MM / dd')}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      );
                                    })
                                  }
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>)}
                  <div className="mt-5">
                    <div className="mt-4">
                      {
                        visibleQuestions.map((question) => {
                          const { id, description, type, } = question;
                          const answer = answers[id];
                          return (
                            <div key={id} className="mb-3">
                              <Label>{translate(description)}</Label>
                              <div>
                                {
                                  translate(({
                                    text: answer,
                                    checkbox: keys(answer || {}).join(', '),
                                    radio: answer,
                                    select: answer,
                                    imageFile: answer?.map?.(_ => _.name).join(', ')
                                  })[type] || null)
                                }
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  {
                    hasCaution && <div className="mt-5">
                      <h6 className="font-weight-bold">{translate('注意事項')}</h6>
                      <RichTextContent className="text-muted small" html={cautionText} />
                      <div className="mt-3 card border-3 border-dark p-2 text-center">
                        <Field className="m-0" name="hasAccepted" {...statedFields.hasAccepted} translate={translate} />
                      </div>
                    </div>
                  }
                  <div className="mt-4">
                    {
                      amount > 0 && (
                        <Fragment>
                          {translate(<div className="text-center">
                            <span className="small">参加費用は</span>
                            <span className="mx-2 text-danger">{numeral(amount).format('0,0')} 円(税込)</span>
                            <span className="small">です</span>
                          </div>)}
                          {translate(<div className="mt-3 text-center">
                            お支払い情報を入力してください                    
                          </div>)}
                          {isTestEntry && <Alert color="warning">テスト申込のため、実際に引き落としされません</Alert>}
                          <FormGroup className="mt-3">
                            {translate(<Label>クレジットカード</Label>)}
                            {
                              paymentIntent?.client_secret && (
                                <PaymentElement
                                  options={{
                                    fields: {
                                      billingDetails: {
                                        address: {
                                          country: "never"
                                        }
                                      }
                                    }
                                  }}
                                />
                              )
                            }
                          </FormGroup>
                        </Fragment>
                      )
                    }
                  </div>
                </div>
                {translate(<div className="d-flex mt-5">
                  <AppButton size="lg" className="cancel flex-fill" color="secondary" onClick={onClickBack} disabled={isSubmitting}>
                    <span className="fas fa-arrow-left mr-1" />
                    戻る
                  </AppButton>
                  <AppButton size="lg" className="save flex-fill ml-2" type="submit" color="primary" disabled={isUnsubmittable || isSubmitting}>
                    <span className="fas fa-arrow-right mr-1" />
                    参加を申し込む
                  </AppButton>
                </div>)}
              </section>
            </StripeForm>
          </Elements>
        )
      }
    </div>
  );
};

EntryConfirmForm.disabledTranslation = true;
