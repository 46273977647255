import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, Badge } from 'reactstrap';
import { omit, get, mapValues } from 'lodash';
import { useList } from 'react-use';
import { toast } from 'react-toastify';
import dedent from 'dedent';
import { arrayMoveImmutable } from 'array-move';
import { v4 as uuid } from 'uuid';

import firebase, { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import ListForm from '../ListForm';
import ModalButton from '../ModalButton';
import ModelFormModal from './ModelFormModal';

const { entries } = Object;
const db = firebase.firestore();
const sendSms = functions.httpsCallable('sendSms');
const sendEmail = functions.httpsCallable('sendEmail');
const sendMobile = functions.httpsCallable('sendMobile');

export default function CustomerJourneyFormModal(props) {
  const { fields, rowFields, userTags, useSms, useMobile, isOpen, values, onClickClose, tenant } = props;
  const isNew = !values;
  const statedFields = useFormState(values, fields, isOpen);
  const [rows, { set: setRows, updateAt: updateRowAt }] = useList(get(values, 'customerJourneyRows') || []);
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid) || rows.some((_) => !_.isValid);
  const onClickMove = (index, direction) => {
    const newRows = arrayMoveImmutable(rows, index, index + { up: -1, down: 1 }[direction]);
    setRows(newRows);
  };
  const onTemporarilySubmit = (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    props.onSubmit(
      {
        ...mapValues(statedFields, 'value'),
        customerJourneyRows: rows.map((_) => omit(_, 'isValid')),
      },
      { showsModal: true }
    );
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    props.onSubmit({
      ...mapValues(statedFields, 'value'),
      customerJourneyRows: rows.map((_) => omit(_, 'isValid')),
    });
  };
  console.log(33, rows);

  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalHeader>カスタマージャーニー{isNew ? '追加' : '編集'}</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {entries(statedFields).map(([fieldName, fieldSetting]) => (
            <Field
              key={fieldName}
              name={fieldName}
              values={mapValues(statedFields, 'value')}
              documentName="customerJourney"
              {...fieldSetting}
            />
          ))}
          <div className="my-3">
            <ListForm
              items={rows}
              renderItem={(item, itemIndex) => {
                return (
                  <div>
                    <div className="card p-3">
                      <RowForm
                        index={itemIndex}
                        values={item}
                        tenant={tenant}
                        onChange={(_) => updateRowAt(itemIndex, { ...item, ..._ })}
                        rowFields={rowFields({ userTags, useSms, useMobile, prevRows: rows.slice(0, itemIndex), })}
                        rows={rows}
                        onClickMove={(direction) => onClickMove(itemIndex, direction)}
                        onTemporarilySubmit={onTemporarilySubmit}
                        isUnsubmittable={isUnsubmittable}
                        setRows={setRows}
                      />
                    </div>
                  </div>
                );
              }}
              onChange={(_) => setRows(_)}
              minItems={1}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            保存
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

function RowForm(props) {
  const { setRows, index, values, onChange, tenant, rowFields, rows, onClickMove, onTemporarilySubmit, isUnsubmittable } = props;
  const statedFields = useFormState(values, rowFields, values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every((_) => _.isValid),
    });
    statedFields.startAll();
    // NOTE: hiddenのRichTextEditorがあると、このeffectのonChangeでnullと""が交互に設定されてしまう。nullと""の違いではeffectが動かないように
  }, [...Object.values(statedFields).map((_) => (_.value == null ? '' : _.value))]);

  const onClickSendSms = async ({ displayName, phone, email }) => {
    const {
      body,
      deliveryMethod,
      subject,
      mobileNotificationType,
      mobileNotificationContentType,
      url,
      html,
      mobileTypeDisplayName,
      fixUntil,
    } = values;
    const content = { text: body, url, html }[mobileNotificationContentType] || '';
    try {
      if (deliveryMethod === 'email' || deliveryMethod === 'emailAndMobile') {
        await sendEmail({
          from_name: tenant.name,
          to: [
            {
              email,
              name: displayName,
              type: 'to',
            },
          ],
          subject: `${subject}`,
          text: dedent`
            ${body.replace(/\{\{name\}\}/g, displayName)}
          `,
        });
      }
      if (deliveryMethod === 'sms') {
        await sendSms({ targets: [{ displayName, phone }], text: body });
      }
      if (deliveryMethod === 'mobile' || deliveryMethod === 'emailAndMobile') {
        await sendMobile({
          targets: [{ email, displayName }],
          subject,
          contentType: mobileNotificationContentType,
          content,
          type: mobileNotificationType,
          typeDisplayName: mobileTypeDisplayName,
          fixUntil: fixUntil?.toDate().toISOString(),
        });
      }
      toast.success('送信しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onClickRecreateId = (event) => {    
    if (!window.confirm('明細IDを再生成しますがよろしいですか？')) return;
    const key = uuid();
    statedFields.setValues({ key, });
    setRows((_ => console.log(_, statedFields.key.value) || _)(rows.map(_ => _.conditionRowKey === statedFields.key.value ? ({ ..._, conditionRowKey: key, }) : _)));
  }

  return (
    <div className="d-flex flex-column">
      <div>
        <div className="d-flex justify-content-between align-items-center gap-1">
          <Badge color="info" className="px-2">
            {index + 1}
          </Badge>
          <div className="d-flex gap-1">
            <Button size="sm" onClick={() => onClickMove('up')} disabled={index === 0}>
              <span className="fas fa-caret-up" />
            </Button>
            <Button size="sm" onClick={() => onClickMove('down')} disabled={index === rows.length - 1}>
              <span className="fas fa-caret-down" />
            </Button>
            <Button
              size="sm"
              className="save ml-2"
              type="submit"
              color="primary"
              onClick={onTemporarilySubmit}
              disabled={isUnsubmittable}
            >
              一時保存
            </Button>
          </div>
        </div>
        {entries(statedFields).map(([fieldName, fieldSetting]) => (
          <Field
            key={fieldName}
            name={fieldName}
            values={mapValues(statedFields, 'value')}
            documentName="customerJourneyRow"
            badges={
              <>
                {[
                  'mobileTypeDisplayName',
                  'subject',
                  'mobileNotificationContentType',
                  ...(values.mobileNotificationContentType === 'text' ? ['body'] : []),
                  'url',
                  'html',
                  'mobileNotificationType',
                  'fixUntil',
                ].includes(fieldName) && ['emailAndMobile'].includes(values.deliveryMethod) ? (
                  <Badge color="info" className="ml-1">
                    アプリ
                  </Badge>
                ) : null}
                {['subject', 'body'].includes(fieldName) && ['emailAndMobile'].includes(values.deliveryMethod) ? (
                  <Badge color="success" className="ml-1">
                    Eメール
                  </Badge>
                ) : null}
              </>
            }
            {...fieldSetting}
          />
        ))}
        <div className="d-flex gap-1">
          <ModalButton
            Modal={ModelFormModal}
            modalProps={{
              title: 'テスト送信',
              submitLabel: '送信',
              fields: {
                displayName: { type: 'string', label: '名前' },
                ...(['email', 'mobile', 'emailAndMobile'].includes(values.deliveryMethod)
                  ? { email: { type: 'string', label: 'メールアドレス' } }
                  : {}),
                ...(['sms'].includes(values.deliveryMethod) ? { phone: { type: 'string', label: '電話番号' } } : {}),
              },
              onSubmit: onClickSendSms,
            }}
          >
            <span className="fas fa-paper-plane mr-1" />
            テスト送信
          </ModalButton>
          <Button
            size="sm"
            className="save ml-2"
            type="button"
            onClick={onClickRecreateId}
            disabled={isUnsubmittable}
          >
            明細ID再生成
          </Button>
          <Button
            size="sm"
            className="save ml-2"
            type="submit"
            color="primary"
            onClick={onTemporarilySubmit}
            disabled={isUnsubmittable}
          >
            一時保存
          </Button>
        </div>
      </div>
    </div>
  );
}
