import { useEffect, useRef, useState, } from 'react';
import qs from 'qs';
import qrcode from 'qrcode';
import copy from 'copy-to-clipboard';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { Button, Badge, Input, } from 'reactstrap';

import firebase from '../firebase';
import useTenant from './hooks/useTenant';

const db = firebase.firestore();
const eventsRef = db.collection('events');

export default function EventQrcode({ event, forTheDay = true, force = false, forceLectureId, forceFrameIndex, }) {
  const canvasEl = useRef(null);
  const tenant = useTenant();
  const [forceKey, setForceKey] = useState();
  const url = event && (
    `${window.location.origin}/${tenant?.id}/events/${event.id}?${qs.stringify({ ...(forTheDay && { key: event.theDayKey, }), ...(force && { forceKey, }), })}`
  );
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };
  useEffect(() => {
    if(force) {
      const key = uuid();
      setForceKey(key);
      eventsRef.doc(event.id).collection('forceKeys').doc(key).set({
        lectureId: forceLectureId,
        frameIndex: forceFrameIndex,
        createdAt: new Date(),
      });
    }
  }, []);

  useEffect(() => {
    if (canvasEl.current && event && (!forTheDay || event.theDayKey)) {
      const size = 350;
      const fontSize = 20;
      qrcode.toCanvas(canvasEl.current, url, { width: size, height: size, errorCorrectionLevel: 'H' });
      if (!event.textForQr) return;

      const text = event.textForQr;
      const ctx = canvasEl.current.getContext('2d');
      ctx.font = `${fontSize}px sans-serif`;
      const { width: textWidth } = ctx.measureText(text);
      const paddingSize = 5;
      const rectWidth = textWidth + paddingSize * 2;
      const textHeight = fontSize;
      const rectHeight = textHeight + paddingSize * 2
      ctx.fillStyle = 'white';
      ctx.fillRect(size / 2 - rectWidth / 2, size / 2 - rectHeight / 2, rectWidth, rectHeight);
      ctx.fillStyle = 'black';
      ctx.fillText(text, size / 2 - textWidth / 2, size / 2 + textHeight / 2 - 3);
    }
  }, [canvasEl, url, forTheDay]);

  useEffect(() => {
    if (event && !event.theDayKey) eventsRef.doc(event.id).update({ theDayKey: uuid() });
  }, [event]);

  return (
    <div>
      <canvas title={url} ref={canvasEl} />
      <div className="mt-4">
        <div className="d-flex">
          <Input className="flex-grow-1 mr-2" readOnly defaultValue={url} />
          <Button color="primary" onClick={onClickCopy}>
            <span className="fas fa-copy" />
          </Button>
        </div>
      </div>
    </div>
  );
}
