const { uniq, uniqBy, keyBy, isEmpty, isFunction, chunk, orderBy } = require('lodash');
const { utcToZonedTime } = require('date-fns-tz');
const { format: formatDate, differenceInDays, differenceInMonths, addHours, startOfDay, endOfDay, addDays } = require('date-fns');
const moji = require('moji');

const { getDocumentData, getCollectionData } = require('./firebase');
const { areaFromPostalCode } = require('./models/setting');
const { taxRate, } = require('./config');
const { systemAuditObject } = require('./auditLogs');

const { random, floor } = Math;

const fieldDisplayValue = (v, { values, type, options: _options, initialValue }) => {
  const value = v !== undefined ? v : initialValue;
  const options = isFunction(_options) ? _options(values) : _options;
  const displayValue = (
    {
      select: (_) => (options.find((_) => _.value === value) || {}).label,
      multiSelect: (_) => (value || []).map((_) => (options.find((o) => o.value === _) || {}).label),
      creatableMultiSelect: (_) => (value || []).map((_) => (options.find((o) => o.value === _) || {}).label),
      date: (_) =>
        value && formatDate(utcToZonedTime(value.toDate ? value.toDate() : value, 'Asia/Tokyo'), 'yyyy/MM/dd'),
      datetime: (_) =>
        value && formatDate(utcToZonedTime(value.toDate ? value.toDate() : value, 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm'),
      boolean: (_) => (value != null ? { true: 'YES', false: 'NO' }[value] : ''),
      file: (_) => value?.name,
      files: (_) =>
        Array.from(value || [])
          .map((_) => _.name)
          .join('\n'),
    }[type] || ((_) => value)
  )();
  return displayValue == null ? '' : displayValue;
};

const generateOrderId = async () => {
  const id = chunk(
    Array(20)
      .fill()
      .map((_) => floor(random() * 10)),
    5
  )
    .map((_) => _.join(''))
    .join('-');
  // TODO: ユニークチェック
  // return !(await ordersRef.doc(id).get()).exists ? id : await generateOrderId();
  return id;
};

const generateRentalOrderId = async () => {
  const id = chunk(
    [
      'R',
      ...Array(19)
        .fill()
        .map((_) => floor(random() * 10)),
    ],
    5
  )
    .map((_) => _.join(''))
    .join('-');
  return id;
};

const generateContentOrderId = async () => {
  const id = chunk(
    [
      'C',
      ...Array(19)
        .fill()
        .map((_) => floor(random() * 10)),
    ],
    5
  )
    .map((_) => _.join(''))
    .join('-');
  return id;
};

const generateTroubleInquiryId = async () => {
  const id = chunk(
    [
      'T',
      ...Array(19)
        .fill()
        .map((_) => floor(random() * 10)),
    ],
    5
  )
    .map((_) => _.join(''))
    .join('-');
  return id;
};

const generateMethodInquiryId = async () => {
  const id = chunk(
    [
      'M',
      ...Array(19)
        .fill()
        .map((_) => floor(random() * 10)),
    ],
    5
  )
    .map((_) => _.join(''))
    .join('-');
  return id;
};

const generateInquiryId = async () => {
  const id = chunk(
    [
      'I',
      ...Array(19)
        .fill()
        .map((_) => floor(random() * 10)),
    ],
    5
  )
    .map((_) => _.join(''))
    .join('-');
  return id;
};

const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));
const computeUserTargets = async (db, tenantId, uids, options) => {
  const {
    dateType,
    orderType = 'any',
    entryType = 'any',
    userTagIds,
    userChildAgeMin,
    userChildAgeMax,
    userChildVehicleExperiences,
    userChildBlank,
    startDate,
    endDate,
    scheduleType,
    daysAfter,
    areaGroups
  } = options;
  let candidates = uids.map((uid) => ({ uid }));

  // NOTE: 注文条件で絞る
  candidates = await {
    ordered: async () => {
      return chunk(candidates, 500).reduce(async (x, data, i) => {
        const prevs = await x;

        const results = (
          await Promise.all(
            data.map(async (candidate) => {
              const { uid } = candidate;
              let ordersRef = db.collection('orders').where('createdBy.uid', '==', uid).where('tenantId', '==', tenantId);
              if (dateType === 'ship') {
                if(startDate) ordersRef = ordersRef.where('shippedDate', '>=', formatDate(startDate.toDate?.() || startDate, 'yyyy/MM/dd'));
                if(endDate) ordersRef = ordersRef.where('shippedDate', '<=', formatDate(endDate.toDate?.() || endDate, 'yyyy/MM/dd'));
              }
              if (dateType === 'order') {
                if(startDate) ordersRef = ordersRef.where('createdAt', '>=', startOfDay(startDate.toDate?.() || startDate));
                if(endDate) ordersRef = ordersRef.where('createdAt', '<=', endOfDay(endDate.toDate?.() || endDate));
              }
              if (scheduleType === 'ship') {
                if (daysAfter) ordersRef = ordersRef.where('shippedDate', '==', formatDate(addDays(new Date(), -daysAfter), 'yyyy/MM/dd'));
              }
              let filteredOrders = await getCollectionData(ordersRef);
              filteredOrders = filteredOrders.filter(_ => _.cancelledAt == null && !_.viaInquiry && !_.viaTroubleInquiry);
              if (!isEmpty(options.conditionProductTypeIds)) {
                const conditionProducts = await getCollectionData(
                  db.collection('products').where('productTypeIds', 'array-contains-any', options.conditionProductTypeIds)
                );
                filteredOrders = filteredOrders.filter((_) =>
                  _.productIds?.some((_) => conditionProducts.map((_) => _.id).includes(_))
                );
              }
              if (options.isBodyOnly) {
                const products = await getCollectionData(db.collection('products').where('isBody', '==', true));
                filteredOrders = filteredOrders.filter((_) => products.some(({ id }) => _.productIds.includes(id)));
              }
              if (!isEmpty(options.conditionProductIds)) {
                const conditionProducts = await Promise.all(
                  options.conditionProductIds.map((_) => getDocumentData(db.collection('products').doc(_)))
                );
                filteredOrders = filteredOrders.filter((_) =>
                  _.productIds?.some((_) => conditionProducts.map((_) => _.id).includes(_))
                );
              }
              const [lastOrder] = orderBy(
                filteredOrders.filter(({ contactorName, contactorPhone }) => contactorName && contactorPhone),
                [({ createdAt }) => createdAt.toDate()],
                ['desc']
              );
              const contactor = lastOrder && { name: lastOrder.contactorName, phone: lastOrder.contactorPhone };
              const conditions = { ...candidate.conditions, order: filteredOrders.length > 0 && orderBy(filteredOrders, [({ createdAt }) => createdAt.toDate()], ['desc'])[0] }
              return filteredOrders.length > 0 ? { ...candidate, lastOrder, contactor, conditions } : null;
            })
          )
        ).filter((_) => _);
        return [...prevs, ...results];
      }, Promise.resolve([]));
    },
    notOrdered: async () => {
      return chunk(candidates, 500).reduce(async (x, data, i) => {
        const prevs = await x;

        const results = (
          await Promise.all(
            data.map(async (candidate) => {
              const { uid } = candidate;
              const orders = await getCollectionData(db.collection('orders').where('createdBy.uid', '==', uid).where('tenantId', '==', tenantId).limit(1));
              return orders.length === 0 ? candidate : null;
            })
          )
        ).filter((_) => _);
        return [...prevs, ...results];
      }, Promise.resolve([]));
    },
    any: async () => candidates,
  }[orderType]();

  // NOTE: イベント参加で絞る
  candidates = await {
    entried: async () => {
      return chunk(candidates, 500).reduce(async (x, data, i) => {
        const prevs = await x;

        const results = (
          await Promise.all(
            data.map(async (candidate) => {
              const { uid } = candidate;
              let filteredEntries = await getCollectionData(db.collectionGroup('entries').where('createdBy.uid', '==', uid).where('tenantId', '==', tenantId));
              if (!isEmpty(options.conditionEventIds)) {
                const conditionEvents = await Promise.all(
                  options.conditionEventIds.map((_) => getDocumentData(db.collection('events').doc(_)))
                );
                let conditionLectures = (
                  await Promise.all(conditionEvents.map((_) => getCollectionData(_.ref.collection('lectures'))))
                ).flat();
                if (dateType === 'entry') {
                  if(startDate) conditionLectures = conditionLectures.filter(_ => _.date.toDate() >= startOfDay(startDate.toDate?.() || startDate));
                  if(endDate) conditionLectures = conditionLectures.filter(_ => _.date.toDate() <= endOfDay(endDate.toDate?.() || endDate));
                }
                if (scheduleType === 'entry') {
                  if(daysAfter) conditionLectures = conditionLectures.filter(_ => _.date.toDate() >= startOfDay(addDays(new Date(), -daysAfter)) && _.date.toDate() <= endOfDay(addDays(new Date(), -daysAfter)));
                }
                filteredEntries = filteredEntries
                  .filter((_) => _.lectureIds?.some((_) => conditionLectures.map((_) => _.id).includes(_)))
                  .filter(_ => {
                    if (options.isCancellOrAbortOnly) return _.cancelledAt || _.abortedAt;
                    return options.isIncludedCancellOrAbort || (!_.cancelledAt && !_.abortedAt);
                  });
              }
              const conditions = { ...candidate.conditions, entry: filteredEntries.length > 0 && orderBy(filteredEntries, [({ createdAt }) => createdAt.toDate()], ['desc'])[0] }
              return filteredEntries.length > 0 ? { ...candidate, conditions } : null;
            })
          )
        ).filter((_) => _);
        return [...prevs, ...results];
      }, Promise.resolve([]));
    },
    notEntried: async () => {
      return chunk(candidates, 500).reduce(async (x, data, i) => {
        const prevs = await x;

        const results = (
          await Promise.all(
            data.map(async (candidate) => {
              const { uid } = candidate;
              const entries = await getCollectionData(db.collectionGroup('entries').where('createdBy.uid', '==', uid));
              return entries.length === 0 ? candidate : null;
            })
          )
        ).filter((_) => _);
        return [...prevs, ...results];
      }, Promise.resolve([]));
    },
    any: async () => candidates,
  }[entryType]();

  const agents = await getCollectionData(db.collection('agents'));
  const agentUserIds = agents.map(({ members }) => Object.keys(members || {})).flat();
  // NOTE: ユーザー条件で絞る
  let users = await chunk(candidates, 500).reduce(async (x, data, i) => {
    const prevs = await x;

    const results = await Promise.all(
      data.map(async ({ uid, lastOrder, contactor, conditions }) => {
        const user = await getDocumentData(db.collection(`tenants/${tenantId}/tenantUsers`).doc(uid));
        return { ...user, lastOrder, contactor, conditions, isAgent: agentUserIds.includes(uid) };
      })
    );
    return [...prevs, ...results];
  }, Promise.resolve([]));

  if (!isEmpty(userTagIds)) {
    users = users.filter((user) => {
      return userTagIds.some((_) => user.userTagIds?.includes(_));
    });
  }
  if (userChildAgeMin != null || userChildAgeMax != null || !isEmpty(userChildVehicleExperiences) || !isEmpty(userChildBlank)) {
    const filterChildAgeAndVehicleExperiences = (child) => {
      const { birthday, vehicleExperiences } = child;
      const age = birthday ? differenceInDays(new Date(), birthday.toDate()) / 365 : 0;
      return [
        userChildAgeMin == null || age >= userChildAgeMin,
        userChildAgeMax == null || age <= userChildAgeMax,
        isEmpty(userChildVehicleExperiences) ||
          userChildVehicleExperiences.some((_) => ['未選択', ...(vehicleExperiences || [])].includes(_)),
      ].every((_) => _);
    };
    users = users
      .filter((user) => {
        if (!user.children || !user.children.length) return userChildBlank === '含む';
        return (user.children || []).some(filterChildAgeAndVehicleExperiences);
      })
      .map((user) => {
        const children = (user.children || []).filter(filterChildAgeAndVehicleExperiences);
        const conditions = { ...user.conditions, children };
        return { ...user, conditions };
      });
  }
  if (!isEmpty(areaGroups)) {
    const areaSetting = await getDocumentData(db.collection('settings').doc([tenantId, 'area'].join('__')));
    users = users.filter((user) => {
      const setting = areaFromPostalCode(user.postalCode, areaSetting);
      return setting && areaGroups.includes(setting.group);
    })
  }

  // NOTE: 無効化されているユーザーを除く
  users = users.filter((_) => !_.disabledAt);

  return uniqBy(orderBy(users, [({ contactor }) => (contactor ? 1 : 0)], ['desc']), 'id');
};

const computeSmsTargets = async (db, tenantId, uids, options) => {
  const {
    magazineId,
    magazineGroupIds = [],
    key: customerJourneyKey,
    deliveryMethod,
  } = options;
  return (await computeUserTargets(db, tenantId, uids, options))
    // NOTE: SMS配信停止しているユーザーを除く
    .filter((_) => !_[`blocksSms__${tenantId}`])
    // NOTE: グループ単位でSMS配信停止しているユーザーを除く
    .filter((_) => !magazineGroupIds.some((magazineGroupId) => _[`blocksMagazineGroupIds__${tenantId}`]?.includes(magazineGroupId)))
    // NOTE: 同一magazineの配信をしない
    .filter((_) => !_.receivedMagazineIds?.includes(magazineId))
    // NOTE: 同一customerJourneyの配信をしない
    .filter((_) => !_.receivedCustomerJourneyKeys?.includes(customerJourneyKey))
    // NOTE: ビジネスアカウントは配信しない
    .filter((_) => !_.isAgent)
    // NOTE: アプリ通知の場合、インストール済ユーザーにのみ通知する
    .filter((_) => deliveryMethod === 'mobile' ? _.appInstalled : true);
};

const computeEnvelopeTargets = async (db, tenantId, uids, options) => {
  const {
    envelopeScheduleId,
  } = options;
  return (await computeUserTargets(db, tenantId, uids, options))
    // NOTE: DM停止しているユーザーを除く
    .filter((_) => !_.ngDm)
    // NOTE: 同一envelopeScheduleの配信をしない
    .filter((_) => !_.receivedEnvelopeScheduleIds?.includes(envelopeScheduleId))
    // NOTE: ビジネスアカウントは配信しない
    .filter((_) => !_.isAgent);
};

const userConditionText = (
  { userTagIds, userChildAgeMin, userChildAgeMax, userChildVehicleExperiences },
  { userTagsById = {} } = {}
) => {
  const userTagText = `ユーザータグ:${(userTagIds || []).map((_) => userTagsById[_]?.name).join(',') || '(指定なし)'}`;
  const childText =
    'お子様:' +
    ([
      userChildAgeMin != null ? `${userChildAgeMin}歳以上` : '',
      userChildAgeMax != null ? `${userChildAgeMax}歳以下` : '',
      !isEmpty(userChildVehicleExperiences) ? '乗り物経験: ' + userChildVehicleExperiences?.join(', ') : '',
    ]
      .filter((_) => _)
      .join(' ') || '(指定なし)');
  return [userTagText, childText].join(' ');
};

const ageDisplay = (birthday, date = new Date()) => {
  const monthsCount = differenceInMonths(date, birthday);
  return `${floor(monthsCount / 12)}歳${monthsCount % 12}ヶ月`;
};

const toHankaku = (_) =>
  moji(_ || '')
    .convert('ZE', 'HE')
    .convert('ZS', 'HS')
    .toString()
    .replace(/ー/g, '-');

const serial = async (xs, f) => {
  return await xs.reduce(async (_, x) => {
    return [...(await _), await f(x)];
  }, Promise.resolve([]));
};

const computeWithoutTax = (amount) => {
  return Math.round(amount / (1 + taxRate));
};

const postalCodeWithHyphen = _ => _ && (_.slice(0,3) + '-' + _.slice(3));

module.exports = {
  fieldDisplayValue,
  generateOrderId,
  generateRentalOrderId,
  generateContentOrderId,
  generateTroubleInquiryId,
  generateMethodInquiryId,
  generateInquiryId,
  computeSmsTargets,
  computeEnvelopeTargets,
  userConditionText,
  ageDisplay,
  toHankaku,
  sleep,
  serial,
  computeWithoutTax,
  postalCodeWithHyphen,
};
