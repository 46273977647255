const { get, } = require('lodash');
const dedent = require('dedent');

const areaFromPostalCode = (postalCode, areaSetting) => {
  return get(areaSetting, ['data', postalCode?.slice(0, 3)]);
};

module.exports = {
  cartSettingsFields: ({ userTags, }) => {
    return {
      deliveryDescription: {
        label: '配送について',
        type: 'text',
      },
      deliveryDateTimeDescription: {
        label: 'お届け日時についての説明',
        type: 'richText',
      },
      deliveryDateEnabled: {
        label: '配送希望日 指定可',
        type: 'boolean',
        initialValue: true,
      },
      deliveryDateDescription: {
        label: '配送希望日 説明文',
        type: 'text',
      },
      deliveryTimeDescription: {
        label: '配送希望時間帯 説明文',
        type: 'text',
      },
      wishHeader: {
        label: 'おねだりカートトップ',
        type: 'richText',
      },
      wishThanksHeader: {
        label: 'おねだりサンクストップ',
        type: 'richText',
      },
      wishOrderUserTagIds: {
        label: 'おねだり注文時ユーザータグ',
        type: 'multiSelect',
        options: userTags.map(_ => ({ label: _.name, value: _.id, })),
      },
    };
  },
  emailSettingsFields: ({ tenant }) => {
    return {
      bodyFooter: {
        label: '共通メール末尾',
        type: 'text',
        rows: 5,
      },
      enBodyFooter: {
        label: '共通メール末尾(英語)',
        type: 'text',
        rows: 5,
      },
      internalMailSubjectPrefix: {
        label: '社内向けメール タイトル接頭辞',
        type: 'string',
      },
      orderConfirmationMailBcc: {
        label: '注文完了メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      orderConfirmationMailBodyHeader: {
        label: '注文完了メール本文 冒頭',
        type: 'text',
        rows: 5,
      },
      orderConfirmationMailBodyHeaderForBodyProduct: {
        label: '注文完了メール本文 冒頭追加分(本体商品のみ)',
        type: 'text',
        rows: 5,
      },
      orderConfirmationMailBodyDeliveryDescription: {
        label: '注文完了メール本文 配送方法',
        type: 'text',
        rows: 5,
      },
      orderCancelRequestConfirmationMailBcc: {
        label: '注文キャンセルリクエスト受付メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      orderCancelMailBcc: {
        label: '注文キャンセルメールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      orderCancelMailBodyFooter: {
        label: '注文キャンセルメール本文 末尾',
        type: 'text',
        rows: 5,
      },
      wishOrderPaidMailToOrdererBcc: {
        label: 'おねだり注文決済メール(注文者へ)BCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      wishOrderPaidMailToPayerBcc: {
        label: 'おねだり注文決済メール(決済者へ)BCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      wishOrderPaidMailToOrdererBodyHeader: {
        label: 'おねだり注文決済メール(注文者へ) 本文 冒頭',
        type: 'text',
        rows: 5,
      },
      wishOrderPaidMailToPayerBodyHeader: {
        label: 'おねだり注文決済メール(決済者へ) 本文 冒頭',
        type: 'text',
        rows: 5,
      },
      mailForAgentRelatedToOrderFooter: {
        label: '注文関連メール(紹介代理店向け)本文 末尾',
        type: 'text',
        rows: 5,
      },
      entryConfirmationMailBcc: {
        label: 'イベント申込完了メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      entryCancelMailBcc: {
        label: 'イベント申込キャンセル完了メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      entryAbortedMailBcc: {
        label: 'イベント中止メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      entryRefundedMailBcc: {
        label: 'イベント参加費返金完了メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryConfirmationMailBcc: {
        label: '不具合問合せ完了メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryShipmentMailBcc: {
        label: '不具合問合せパーツ発送メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryReimbursementRequestMailBcc: {
        label: '不具合問合せ立替工賃申請受付メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryDiscountRequestMailTo: {
        label: '不具合問合せ割引申請メールTO',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryDiscountRequestRejectedMailCc: {
        label: '不具合問合せ割引申請否認メールCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryDiscountRequestApprovedMailCc: {
        label: '不具合問合せ割引申請承認メールCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryApprovalRequestMailTo: {
        label: '不具合問合せ対応の承認申請メールTO',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryRejectedMailCc: {
        label: '不具合問合せ対応の否認メールCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryApprovedMailCc: {
        label: '不具合問合せ対応の承認メールCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      troubleInquiryReplyEmailReplyTo: {
        label: '不具合問合せ対応のメールReplyTo',
        type: 'string',
      },
      troubleInquiryReplyEmailBcc: {
        label: '不具合問合せ対応のメールBCC',
        type: 'string',
      },
      methodInquiryConfirmationMailBcc: {
        label: '乗り方問合せ完了メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      methodInquiryReplyEmailReplyTo: {
        label: '乗り方問合せ対応のメールReplyTo',
        type: 'string',
      },
      methodInquiryReplyEmailBcc: {
        label: '乗り方問合せ対応のメールBCC',
        type: 'string',
      },
      inquiryConfirmationMailBcc: {
        label: '問合せ完了メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      inquiryShipmentMailBcc: {
        label: '問合せパーツ発送メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      inquiryReimbursementRequestMailBcc: {
        label: '問合せ立替工賃申請受付メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      inquiryApprovalRequestMailTo: {
        label: '問合せ対応の承認申請メールTO',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      inquiryRejectedMailCc: {
        label: '問合せ対応の否認メールCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      inquiryApprovedMailCc: {
        label: '問合せ対応の承認メールCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      inquiryReplyEmailReplyTo: {
        label: '問合せ対応のメールReplyTo',
        type: 'string',
      },
      inquiryReplyEmailBcc: {
        label: '問合せ対応のメールBCC',
        type: 'string',
      },
      supportSurveryMailBcc: {
        label: '問合せ対応アンケートメールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      agentSignedUpMailTo: {
        label: '代理店サインアップ完了メールTo',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      agentShopCreatedByAgentMailTo: {
        label: '代理店による店舗追加の通知メールTO',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      eventTestMailAddress: {
        label: 'イベントテスト申込用メールアドレス',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      surveyAnswerReplyEmailReplyTo: {
        label: 'アンケートページ回答対応のメールReplyTo',
        type: 'string',
      },
      surveyAnswerReplyEmailBcc: {
        label: 'アンケートページ回答対応のメールBCC',
        type: 'string',
      },
      surveyAnswerShipmentMailBcc: {
        label: 'アンケートページ回答オプション発送メールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      shippedMailBody: {
        label: '発送完了メール本文',
        type: 'text',
        hint: dedent`
          注文者名: {{ORDERER_NAME}}
          注文番号: {{ORDER_NUMBER}}
          注文日: {{ORDER_DATE}}
          注文内容: {{ORDER_DETAIL}}
          請求金額: {{BILLIN_AMOUNT}}
          お届け先住所: {{DESTINATION_ADDRESS}}
          お届け先名: {{DESTINATION_NAME}}
          お荷物伝票番号: {{TRACKING_NUMBER}}
          お届け予定日: {{DELIVERY_DATE}}
          お届け予定時間: {{DELIVERY_TIME}}
        `,
        hidden: _ => !tenant.useWms,
        rows: 10,
      },
      poEmailTo: {
        label: 'POメールTO',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      poEmailBcc: {
        label: 'POメールBCC',
        type: 'string',
        hint: 'カンマ区切りで複数指定できます',
      },
      poEmailReplyTo: {
        label: 'POメールReplyTo',
        type: 'string',
      },
    };
  },
  textSettingsFields: ({ tenant }) => {
    return {
      troubleInquiryPageHeaderHtml: {
        label: '問合せ・不具合問合せページ冒頭',
        type: 'richText',
        rows: 10,
      },
      productsPageHeaderHtml: {
        label: '商品ページ冒頭',
        type: 'richText',
        rows: 10,
      },
      giftOutOfStockText: {
        label: 'プレゼント在庫不足時のメッセージ（下部）',
        type: 'text',
        initialValue: 'が在庫不足のためプレゼントできません',
      },
      orderCancelConfirmMessageText: {
        label: 'キャンセル or 内容変更の確認メッセージ冒頭',
        type: 'text',
      },
      deliverySlipHeaderText: {
        label: '納品書上部 一行テキスト',
        type: 'string',
        validations: {
          maxLength: v => (v || '').length <= 50,
        },
        hint: '50文字以内',
        hidden: _ => !tenant.useWms,
      },
      deliverySlipBottomLeft: {
        label: '納品書左下部',
        type: 'richText',
        rows: 10,
      },
      deliverySlipBottomRight: {
        label: '納品書右下部',
        type: 'richText',
        rows: 10,
      },
      poTopRight: {
        label: 'PO右上部',
        type: 'richText',
        rows: 10,
      },
    };
  },
  areaFromPostalCode,
};
