import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { format as formatDate, endOfDay, } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { useToggle } from 'react-use';
import sanitizeHtml from 'sanitize-html';
import dedent from 'dedent';
import { sortBy, get, keyBy, omit, omitBy, pick, isUndefined, } from 'lodash';
import classnames from 'classnames';

import { activateRichTextHtml } from '../../util';
import { canUpdateEvent, canCreateLecture, canUpdateLecture, canDeleteLecture, canCreateEventInvitation, } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { getCollectionData } from '../../shared/firebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import PriceDisplay from '../PriceDisplay';
import EventInvitationModal from '../modals/EventInvitationModal';
import EventFormModal from '../modals/EventFormModal';
import AdminEventHeader from '../AdminEventHeader';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';
import DeleteButton from '../DeleteButton';
import ProgressButton from '../ProgressButton';
import QueryBoolean from '../QueryBoolean';
import { lectureLevels, lectureAges } from '../../shared/config';
import { fields, fieldsForInvitedUser, } from '../../shared/models/event';
import { abortFields, replaceAbortedMail, } from '../../shared/models/lecture';
import TenantLink from '../TenantLink';
import RichTextContent from '../RichTextContent';

const { keys } = Object;
const db = firebase.firestore();
const usersRef = db.collection('users');
const eventsRef = db.collection('events');
const placesRef = db.collection('places');
const sendAddEventLectureNotifications = functions.httpsCallable('sendAddEventLectureNotifications');
const lectureCloneFields = {
  date: {
    label: '開催日',
    type: 'date',
    validations: {
      required: (v) => v != null,
    },
    hint: `日程追加通知ボタンを押すまで、ユーザーには通知されません`,
  },
};

export default AdminPage(function AdminEvent (props) {
  const { user, match: { params: { eventId } }, addBreadNavValues } = props;
  const { showsAllLectures, onlyWeekend, } = useQueryParams();
  const { firebaseUser } = useFirebaseUser();
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const lectures = useCollectionSubscription(eventsRef.doc(eventId).collection('lectures').orderBy('date'), [eventId]);

  let filteredLectures = lectures;
  if(showsAllLectures !== '1') {
    filteredLectures = filteredLectures.filter(_ => new Date() <= endOfDay(_.date.toDate()));
  }
  if(onlyWeekend === '1') {
    filteredLectures = filteredLectures.filter(_ => [6, 0].includes(_.date.toDate().getDay()));
  }

  const _invitedUsers = useCollectionSubscription(eventsRef.doc(eventId).collection('invitedUsers'), [eventId]);
  const invitedUsers = useDocumentsFetch(_invitedUsers.map(_ => usersRef.doc(_.id)), [_invitedUsers]);
  const staffs = useCollectionsFetchInTenant(['admin', 'staff'].map(_ => usersRef.where('role', '==', _)));
  const eventProductTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('eventProductTypes')), _ => _.createdAt.toDate());
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), _ => _.createdAt.toDate());
  const eventProductTypesById = keyBy(eventProductTypes, 'id');
  const lectureTypesById = keyBy(lectureTypes, 'id');
  const staffsById = keyBy(staffs, 'id');
  const pic = staffsById[event && event.picId];
  const places = useCollectionSubscriptionInTenant(placesRef);
  const [showsInvitationModal, toggleInvitationModal] = useToggle(false);
  const { isPublic = false } = event || {};
  const processCloneValues = lecture => ({ date, }) => {
    return {
      tenantId: event.tenantId,
      ...omit(lecture, ['id', 'ref', 'entryStartDate', 'sentLectureAddNotificationAt', ...keys(abortFields())]),
      date,
      frames: (lecture.frames || []).map(_ => ({ ..._, entriedCount: 0 })),
    };
  };
  useEffect(() => {
    addBreadNavValues({ event })
  }, [event]);

  return event != null && (
    <div>
      <div className="admin-event container-fluid py-5 position-relative">
        <AdminEventHeader activeTab="general" event={event} user={user} />
        <div className="bg-white p-4">
          <div className="d-flex justify-content-end mb-3">
            <EditButton color="primary" itemRef={eventRef} FormModal={EventFormModal} formProps={{ places, staffs, user }} disabled={!canUpdateEvent(user, event)} />
          </div>
          <table className="table table-bordered align-middle">
            <tbody>
              <tr>
                <th className="table-secondary align-middle">担当者</th>
                <td className="align-middle">
                  {pic && pic.displayName}
                </td>
              </tr>
              <tr>
                <th className="table-secondary align-middle">申込期限</th>
                <td className="align-middle">
                  終了{event.lectureFrameExpireHours || 0}時間前
                </td>
              </tr>
              <tr>
                <th className="table-secondary align-middle">説明文</th>
                <td className="align-middle">
                  <RichTextContent html={event.description || ''} />
                </td>
              </tr>
              <tr>
                <th className="table-secondary align-middle">申込時メール冒頭部の差込文</th>
                <td className="align-middle" style={{ whiteSpace: 'pre-line' }}>
                  {event.confirmationMailHeaderMessage}
                </td>
              </tr>
              <tr>
                <th className="table-secondary align-middle">リマインダーメール本文</th>
                <td className="align-middle" style={{ whiteSpace: 'pre-line' }}>
                  {event.reminderMailBody || fields().reminderMailBody.initialValue}
                </td>
              </tr>
            </tbody>
          </table>
          <hr className="my-5" />
          <div className="row">
            <div className="col-12">
              <h5 className="text-center">
                レクチャー
              </h5>
              <div className="d-flex justify-content-between align-items-end mb-2">
                <div className="d-flex gap-1">
                  <QueryBoolean paramName="showsAllLectures" label="開催日過ぎたものも表示" defaultValue={'0'} />
                  <QueryBoolean paramName="onlyWeekend" label="土日のみ表示" defaultValue={'0'} />
                </div>
                <div>
                  <Button className="lecture-add-button" color="primary" tag={TenantLink} to={`/admin/events/${eventId}/lectures/new`} disabled={isPublic || !canCreateLecture(user, event)}>
                    <span className="fas fa-plus mr-1" />
                    レクチャー追加
                  </Button>
                </div>
              </div>
              {
                lectures.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th>開催日</th>
                        <th>商品</th>
                        <th>レクチャー種別</th>
                        <th>対象レベル</th>
                        <th>対象年齢</th>
                        <th>税込価格</th>
                        <th>日程追加通知</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        filteredLectures.map((lecture) => {
                          const { id, date, productType, lectureType, lectureLevel, lectureAge, price, isUserFree, abortedAt, sentLectureAddNotificationAt, } = lecture;
                          const onClickNotify = async () => {
                            if(!window.confirm(`日程追加を通知します。よろしいですか？`)) return;
                            
                            try {
                              await sendAddEventLectureNotifications({ eventId: event.id, lectureId: id, });
                              toast.success('日程追加を通知しました');
                            } catch (e) {
                              toast.error('失敗しました');
                              console.error(e);
                            }
                          };
                          const beforeDelete = async () => {
                            if(
                              (await event.ref.collection('entries').get()).docs.some(_ => get(_.data(), 'frames', []).some(_ => _.lectureId === id))
                            ) {
                              toast.error('使用されているため削除できません');
                              return false;
                            }
                          };

                          return (
                            <tr key={id} className={classnames({ 'table-secondary text-muted': abortedAt != null })}>
                              <td>
                                <TenantLink to={`/admin/events/${eventId}/lectures/${id}`}>
                                  {date && formatDate(date.toDate(), 'yyyy/MM/dd(iii)', { locale: ja })}
                                </TenantLink>
                                {abortedAt != null && <div><span className="badge badge-warning">中止済み</span></div>}
                              </td>
                              <td>
                                {eventProductTypesById[productType]?.name}
                              </td>
                              <td>
                                {lectureTypesById[lectureType]?.name}
                              </td>
                              <td>
                                {lectureLevels[lectureLevel]}
                              </td>
                              <td>
                                {lectureAges[lectureAge]}
                              </td>
                              <td className="text-right">
                                <PriceDisplay price={price} isUserFree={isUserFree} />
                              </td>
                              <td className="text-right">
                                {sentLectureAddNotificationAt && formatDate(sentLectureAddNotificationAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                              </td>
                              <td className="text-right text-nowrap">
                                <ProgressButton size="sm" className="mr-1" process={onClickNotify}>
                                  日程追加通知
                                </ProgressButton>
                                <AddButton icon={<span className="fas fa-copy mr-1" />} processValues={processCloneValues(lecture)} label="複製" itemRef={_ => event.ref.collection('lectures').doc()} size="sm" className="lecture-clone-button mr-1" color="secondary" disabled={isPublic || !canUpdateLecture(user, event)} formProps={{ title: '複製', fields: lectureCloneFields }} FormModal={ModelFormModal} />
                                <Button size="sm" className="lecture-edit-button mr-1" color="secondary" tag={TenantLink} to={`/admin/events/${eventId}/lectures/${id}/edit`} disabled={!canUpdateLecture(user, event)}>
                                  <span className="fas fa-edit mr-1" />
                                  編集
                                </Button>
                                <ModalButton
                                  size="sm"
                                  className="mr-1"
                                  color="warning"
                                  disabled={abortedAt != null}
                                  Modal={ModelFormModal}
                                  modalProps={({ toggleModal }) => {
                                    const handleSubmit = async (values) => {
                                      const message = dedent`
                                        以下のようなメールを送り、中止を実行します。よろしいですか？

                                        -----------------------------

                                        ${replaceAbortedMail(values.abortMailBody, event, { ...lecture, ...values })}
                                      `;
                                      if (!window.confirm(message)) return;

                                      try {
                                        const batch = db.batch();
                                        batch.update(lecture.ref, {
                                          ...values,
                                          updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
                                        });
                                        const entries = await getCollectionData(event.ref.collection('entries').where('lectureIds', 'array-contains', id));
                                        entries
                                          .filter(_ => _.cancelledAt == null)
                                          .filter(_ => _.frames.some(f => new Date(`${date?.toDate().toDateString()} ${f.lectureFrame.startAt.toDate().toTimeString()}`) >= values.abortedAt))
                                          .map(_ => batch.update(_.ref, {
                                            updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
                                            abortedAt: values.abortedAt,
                                          }));
                                        await batch.commit();
                                        toast.success('中止しました');
                                        toggleModal(false);
                                      } catch (e) {
                                        console.error(e);
                                        toast.error('失敗しました');
                                      }
                                    };

                                    return {
                                      title: '中止',
                                      fields: abortFields(),
                                      submitLabel: '中止する',
                                      onSubmit: handleSubmit,
                                    };
                                  }}
                                >
                                  <span className="fas fa-ban mr-1" />
                                  中止
                                </ModalButton>
                                <DeleteButton size="sm" itemRef={eventsRef.doc(eventId).collection('lectures').doc(id)} disabled={isPublic || !canDeleteLecture(user, event)} beforeDelete={beforeDelete} />
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                ) : (
                  <div>
                    レクチャーは未登録です
                  </div>
                )
              }
            </div>
          </div>
          <hr className="my-5" />
          <div className="row">
            <div className="col-12">
              <h5 className="text-center">
                招待アカウント
              </h5>
              <div className="d-flex justify-content-end mb-3 gap-1">
                <EditButton label="連絡メモ" itemRef={eventRef} FormModal={ModelFormModal} formProps={{ title: '連絡メモ', fields: fieldsForInvitedUser(), }} />
                <Button color="primary" onClick={toggleInvitationModal} disabled={!canCreateEventInvitation(user, event)}>
                  <span className="fas fa-link mr-1" />
                  招待する
                </Button>
              </div>
              {
                invitedUsers.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th>アカウント名</th>
                        <th>メールアドレス</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (invitedUsers || []).map((user) => {
                          const { id, displayName, email } = user;
                          return (
                            <tr key={id}>
                              <td>
                                {displayName}
                              </td>
                              <td>
                                {email}
                              </td>
                              <td className="text-right">
                                <DeleteButton itemRef={eventsRef.doc(eventId).collection('invitedUsers').doc(id)} className="ml-2" />
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                ) : (
                  <div>
                    招待アカウントはまだありません
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <EventInvitationModal user={user} eventId={eventId} isOpen={showsInvitationModal} onClickClose={_ => toggleInvitationModal(false)} />
    </div>
  );
});
