import { toast } from 'react-toastify';
import { pick, omit, uniqBy } from 'lodash';
import ModalButton from './ModalButton';
import ModelFormModal from './modals/ModelFormModal';
import useTenant from './hooks/useTenant';

import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';
import { useSettingDocument, tenantAreaSettingRef } from '../models/setting';
import firebase, { updateDoc } from '../firebase';

const db = firebase.firestore();

export const InquiryUpdatePicButton = ({ inquiryRef, inquiry, onSubmit }) => {
  const tenant = useTenant();
  const users = useCollectionSubscriptionInTenant(db.collection('users').where('role', 'in', ['admin', 'staff']));
  // areaSettingのuserはidじゃなくてuid持つようにしたほうがよさそう（userドキュメントとの整合性考えると）
  const picOptions = users.map(_ => ({ label: _.displayName, value: pick(_, ['id', 'email', 'displayName', 'uid']), }));

  return (
    <ModalButton
      Modal={ModelFormModal}
      modalProps={({ toggleModal }) => {
        const handleSubmit = async ({ respondedBy }) => {
          if (!window.confirm('本当に担当を変更しますか？')) return;

          try {
            await updateDoc(inquiry.ref, { respondedBy });
            onSubmit && (await onSubmit(respondedBy));
            toast.success('担当を変更しました');
            toggleModal(false);
          } catch (e) {
            console.error(e);
            toast.error('失敗しました');
          }
        };

        return {
          title: '担当変更',
          submitLabel: '変更',
          fields: {
            respondedBy: { type: 'select', label: '担当', options: picOptions },
          },
          onSubmit: handleSubmit,
        };
      }}
    >
      担当変更
    </ModalButton>
  );
};
