import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { omitBy, isUndefined, mapValues, sortBy, get, keyBy, pick, omit } from 'lodash';
import { useToggle } from 'react-use';
import qs from 'qs';
import retry from 'async-retry';
import { v4 as uuid } from 'uuid';

import firebase, { functions } from '../../firebase';
import texts from '../../shared/texts';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { newFields as fields, acceptFields, newAgentFields, newUserFields, bankFields, } from '../../shared/models/agent';
import { fields as agentShopFields } from '../../shared/models/agentShop';
import { fields as referrerFields } from '../../shared/models/referrer';
import useFormState from '../hooks/useFormState';
import TenantUserPage from '../hocs/TenantUserPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import NewAgentForm from '../forms/NewAgentForm';
import NewAgentConfirmForm from '../forms/NewAgentConfirmForm';

const { keys } = Object;
const storageRef = firebase.storage().ref();
const auth = firebase.auth();
const db = firebase.firestore();
const createUserAndSendEmailVerification = functions.httpsCallable('createUserAndSendEmailVerification');

function AgentNewAgent(props) {
  const {
    user,
    history,
    firebaseUser,
    toggleLoginForm,
    match: {
      params: { tenantPath },
    },
  } = props;
  const { agentRegistrationId, } = useQueryParams();
  const statedFields = useFormState({}, fields({}), false);
  const agentRegistration = useDocumentSubscription(db.collection('agentRegistrations').doc(agentRegistrationId), [agentRegistrationId]);
  const agentContractPlan = useDocumentSubscription(agentRegistration && db.collection('agentContractPlans').doc(agentRegistration.agentContractPlanId), [agentRegistration]);
  const qrBottomDisplays = sortBy(useCollectionSubscriptionInTenant(db.collection('qrBottomDisplays')), _ => _.createdAt.toDate());
  const qrBottomDisplaysById = keyBy(qrBottomDisplays, 'id');
  const nonPartProducts = sortBy(useCollectionSubscriptionInTenant(db.collection('products')), 'code').filter(_ => !_.isPart && _.createdAt != null);
  console.log(statedFields);
  const agentShopStatedFields = useFormState({}, agentShopFields({ forAgent: true, forIndividual: statedFields.corporationOrIndividual.value === 'individual', }), false);
  const referrerStatedFields = useFormState({}, referrerFields({ qrBottomDisplays, forAgent: true, }), false);
  const productsById = keyBy(nonPartProducts, 'id');
  const [showsConfirm, toggleConfirm] = useToggle(false);
  const onSubmitNewAgentForm = async (_values) => {
    toggleConfirm(true);
  };
  const onSubmitConfirm = async () => {
    const values = mapValues(statedFields, 'value');
    try {
      const userValues = await createOrUpdateUser(values);
      const ref = db.collection('agents').doc();
      const agentShopRef = ref.collection('agentShops').doc();
      const batch = db.batch();
      batch.set(ref, {
        ...omit(values, keys(newUserFields())),
        usesReferral: true,
        usesWholesale: false,
        usesPartsWholesale: false,
        hidesAmountInSlip: false,
        isHidden: false,
        agentContractPlanText: agentContractPlan.text,
        showingQrUrls: agentRegistration.showingQrUrlRows || [],
        productsPageHeaderHtml: agentRegistration.productsPageHeaderHtml,
        tenantId: tenantPath,
        createdAt: new Date(),
        byAgent: true,
        members: {
          [userValues.uid]: { role: 'admin', addedAt: new Date(), },
        },
      });
      batch.set(agentShopRef, {
        ...mapValues(agentShopStatedFields, 'value'),
        byAgent: true,
        hidesShopDestination: statedFields.corporationOrIndividual.value === 'individual',
        tenantId: tenantPath,
        addedBy: omitBy(pick(userValues, ['uid', 'email', 'displayName']), isUndefined),
        createdAt: new Date(),
      });
      const referrerKey = uuid();
      batch.set(agentShopRef.collection('referrers').doc(referrerKey), {
        ...mapValues(referrerStatedFields, 'value'),
        key: referrerKey,
        tenantId: tenantPath,
        addedBy: omitBy(pick(userValues, ['uid', 'email', 'displayName']), isUndefined),
        createdAt: new Date(),
      });
      batch.set(ref.collection('agentContractPlanLogs').doc(), {
        notificates: false,
        tenantId: tenantPath,
        text: agentContractPlan.text,
        addedBy: omitBy(pick(userValues, ['uid', 'email', 'displayName']), isUndefined),
        createdAt: new Date(),
      });
      nonPartProducts.map((product) => {
        const productRow = agentRegistration.productRows.find(_ => _.productId === product.id);
        batch.set(ref.collection('agentProductSettings').doc(product.id), {
          isHiddenForReferral: productRow == null,
          isShownForReferral: productRow != null,
          isHiddenForWholesale: false,
          isShownForWholesale: false,
          price: null,
          description: '',
          wholesalePriceRate: null,
          referralFeeType: productRow?.referralFeeType ?? 'rate',
          referralFeeAmount: productRow?.referralFeeAmount ?? null,
          referralFeeRate: productRow?.referralFeeRate ?? null,
          tenantId: tenantPath,
          addedBy: omitBy(pick(userValues, ['uid', 'email', 'displayName']), isUndefined),
          createdAt: new Date(),
        });
      });

      await batch.commit();
      history.push(`/${tenantPath}/agents/${ref.id}`);
      toast.success('お申込みが完了しました');
    } catch (error) {
      console.error(error);
      toast.error('失敗しました');
    }
  };
  const createOrUpdateUser = async (_values) => {
    if (user != null) {
      // NOTE: 更新
      await user.ref.update({ ...pick(_values, ['phone', 'prefecture', 'nameKana', 'postalCode', 'city', 'address']) });
      return user;
    } else {
      const { email, password, } = _values;
      try {
        const userValues = {
          ...pick(_values, ['displayName', 'email', 'phone', 'prefecture', 'nameKana', 'postalCode', 'city', 'address']),
        };
        const {
          data: { uid },
        } = await retry(
          (_) =>
            createUserAndSendEmailVerification({
              userValues,
              password,
              pathname: encodeURIComponent('/mypage/profile'),
              skipsEmailVerification: true,
            }),
          { maxTimeout: 1000 }
        );
        try {
          await auth.signInWithEmailAndPassword(email, password);
        } catch (e) {
          // NOTE: 最悪ログインは失敗しても問題ないのでスルー
          console.error(e);
        }
        return { uid, ...userValues, ref: db.collection('users').doc(uid), };
      } catch (e) {
        console.error(e);
        const code = get(e, 'details.code') || e.code;
        const message = userErrorMessages[code] || '登録に失敗しました';
        toast.error(message);
        throw e;
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showsConfirm]);

  return agentContractPlan != null && (
    <div className="agent-new-agent position-relative h-100 d-flex flex-column mb-5">
      {!showsConfirm ? (
        <NewAgentForm
          onSubmit={onSubmitNewAgentForm}
          onClickLogin={toggleLoginForm}
          {...{ agentRegistration, agentContractPlan, user, statedFields, agentShopStatedFields, referrerStatedFields, productsById, qrBottomDisplaysById, }}
        />
      ) : (
        <NewAgentConfirmForm
          onSubmit={onSubmitConfirm}
          onClickBack={toggleConfirm.bind(null, false)}
          {...{ agentRegistration, agentContractPlan, user, statedFields, agentShopStatedFields, referrerStatedFields, productsById, qrBottomDisplaysById, }}
        />
      )}
    </div>
  );
}

AgentNewAgent.preview = true;

export default TenantUserPage(AgentNewAgent);
