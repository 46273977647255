import React, { useEffect, useRef } from 'react';
import qrcode from 'qrcode';
import qs from 'qs';
import { sortBy, maxBy, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import { format as formatDate, addMonths, startOfDay, endOfDay, } from 'date-fns';
import { Input, Button } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useAsync, } from 'react-use';
import { v4 as uuid } from 'uuid';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { prefectures } from '../../shared/config';
import { beforeDelete, } from '../../util';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import AgentShopHeader from '../AgentShopHeader';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/referrer';
import QueryDateSelector from '../QueryDateSelector';
import QueryBoolean from '../QueryBoolean';
import ModalButton from '../ModalButton';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const ordersRef = db.collection('orders');
const productsRef = db.collection('products');
const checkReferrerOrderExistence = functions.httpsCallable('checkReferrerOrderExistence');
const qrBottomDisplaysRef = db.collection('qrBottomDisplays');

export default AgentPage(function AgentOrders (props) {
  const { tenant, user, agent, match: { params: { agentShopId } }, setBreadNavValues, canReadAllShops, memberData, } = props;
  const {
    showsHidden,
  } = useQueryParams();
  const agentShop = useDocumentSubscription((canReadAllShops || memberData.shopIds.includes(agentShopId)) && agent.ref.collection('agentShops').doc(agentShopId), [agent, agentShopId]);
  const referrers = useCollectionSubscription(agentShop && agentShop.ref.collection('referrers').orderBy('createdAt'), [agentShop]);
  const qrBottomDisplays = sortBy(useCollectionSubscriptionInTenant(qrBottomDisplaysRef), _ => _.createdAt.toDate());
  const qrBottomDisplaysById = keyBy(qrBottomDisplays, 'id');
  const isReachedLimit = referrers.filter(_ => !_.isHidden).length >= (agent?.maxReferrersCount ?? 5);

  let filteredReferrers = referrers;
  if (showsHidden !== '1') {
    filteredReferrers = filteredReferrers.filter(_ => !_.isHidden);
  }

  useEffect(() => {
    setBreadNavValues({ agent, agentShop, });
  }, [agent, agentShop]);

  return agentShop != null && (
    <div>
      <div className="agent-shop container-fluid py-5 position-relative">
        <AgentShopHeader activeTab="referrers" user={user} agent={agent} agentShop={agentShop} />
        <div className="bg-white p-4 py-5" style={{ minHeight: '50vh', }}>
          {isReachedLimit && <div className="alert alert-warning">リファラ数の上限に達しています</div>}
          <div className="mb-2 d-flex align-items-end justify-content-end gap-1">
            <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
            <AddInTenantButton itemRef={agentShop.ref.collection('referrers').doc(uuid())} processValues={_ => ({ ..._, key: _.id })} FormModal={ModelFormModal} formProps={{ title: 'リファラ追加', fields: fields({ qrBottomDisplays }), }} disabled={!canUpdateAgent(user, agent) || isReachedLimit} />
          </div>
          {
            referrers.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>名称</th>
                    <th>QR下部表記</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (filteredReferrers || []).map((referrer) => {
                      const { id, ref, name, qrBottomDisplayId, textForQr, isHidden = false, } = referrer;
                      const beforeDelete = async () => {
                        const { data: { exists } } = await checkReferrerOrderExistence({ referrerId: id });
                        if(exists) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };

                      return (
                        <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                          <td>
                            <TenantLink to={`/agents/${agent.id}/agentShops/${agentShopId}/referrers/${id}`}>
                              {name}
                            </TenantLink>
                          </td>
                          <td>
                            {qrBottomDisplaysById[qrBottomDisplayId]?.name}
                          </td>
                          <td className="text-right">
                            <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'リファラ編集', fields: fields({ qrBottomDisplays, forAgent: true, }), }} disabled={!canUpdateAgent(user, agent)} />
                            <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateAgent(user, agent)} beforeDelete={beforeDelete} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                リファラは未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
