import firebase from '../../firebase';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import InvitedUserPage from '../hocs/InvitedUserPage';
import EventQrcode from '../EventQrcode';

const db = firebase.firestore();
const eventsRef = db.collection('events');

export default InvitedUserPage(function AdminEventQrcode(props) {
  const queryParams = useQueryParams();
  const {
    match: {
      params: { eventId },
    },
  } = props;
  const event = useDocumentSubscription(eventsRef.doc(eventId), [eventId]);
  const force = queryParams.force === '1';

  return (
    <div className='admin-event-qrcode py-5'>
      {event && (
        <>
          <h4 className='text-center'>{event.name}</h4>
          <div className='d-flex justify-content-center'>
            <EventQrcode event={event} force={force} {...(force && { forceLectureId: queryParams.lectureId, forceFrameIndex: parseInt(queryParams.frameIndex, 10), })} />
          </div>
        </>
      )}
    </div>
  );
});
