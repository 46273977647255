import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form } from 'reactstrap';
import { sortBy, omit, mapValues, isDate, get, isEmpty } from 'lodash';
import { differenceInMinutes, addMinutes } from 'date-fns';
import { useList } from 'react-use';
import { toast } from 'react-toastify';

import { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import ListForm from '../ListForm';
import { lectureLevels, lectureAges } from '../../shared/config';
import { picFields } from '../../shared/models/lecture';
import TenantLink from '../TenantLink';
import ModalButton from '../ModalButton';
import ModelFormModal from '../modals/ModelFormModal';

const { entries } = Object;
const fields = ({ surveys = [], questions = [], laterQuestions = [], eventProductTypes, lectureTypes, }) => ({
  date: {
    label: '開催日',
    type: 'date',
    validations: {
      required: (v) => isDate(v),
    },
    initialValue: new Date(),
  },
  entryStartDate: {
    label: '申込開始日',
    type: 'date',
  },
  productType: {
    label: '商品種別',
    type: 'select',
    options: eventProductTypes.map(_ => ({ label: _.name, value: _.id })),
  },
  lectureType: {
    label: 'レクチャー種別',
    type: 'select',
    options: lectureTypes.map(_ => ({ label: _.name, value: _.id })),
  },
  lectureLevel: {
    label: '対象レベル',
    type: 'select',
    options: entries(lectureLevels).map(([k, v]) => ({ label: v, value: k })),
    initialValue: 'beginner',
  },
  lectureAge: {
    label: '対象年齢',
    type: 'select',
    options: entries(lectureAges).map(([k, v]) => ({ label: v, value: k })),
    initialValue: '2-3',
  },
  price: {
    label: '価格',
    type: 'integer',
    initialValue: 0,
  },
  isUserFree: {
    label: 'ユーザーの場合は無料',
    type: 'boolean',
    initialValue: false,
  },
  surveyIds: {
    label: '申込時アンケート',
    type: 'multiSelect',
    options: sortBy(surveys.filter((_) => _.surveyGroupId === 'eventEarlierQuestion'), _ => !!_.isHidden)
      .map((_) => ({ label: (_.isHidden ? '[非表示] ' : '') + `${_.name || ''}（${_.title || ''}）`, value: _.id })),
    initialValue: '',
  },
  laterSurveyId: {
    label: '事後アンケート',
    type: 'select',
    options: sortBy(surveys.filter((_) => _.surveyGroupId === 'eventLaterQuestion'), _ => !!_.isHidden)
      .map((_) => ({ label: (_.isHidden ? '[非表示] ' : '') + `${_.name || ''}（${_.title || ''}）`, value: _.id })),
    initialValue: '',
  },
  questions: {
    label: '申込時アンケート（廃止予定）',
    type: 'multiSelect',
    options: questions.filter((_) => !_.isHidden).map((_) => ({ label: `${_.name} (${_.description})`, value: _.id })),
    initialValue: [],
  },
  laterQuestions: {
    label: '事後アンケート（廃止予定）',
    type: 'multiSelect',
    options: laterQuestions
      .filter((_) => !_.isHidden)
      .map((_) => ({ label: `${_.name} (${_.description})`, value: _.id })),
    initialValue: [],
  },
});

const frameFields = ({ lecture, lectureTypes, }) => {
  return {
    startAt: { label: '開始', type: 'time' },
    endAt: { label: '終了', type: 'time' },
    capacity: {
      label: '定員',
      type: 'integer',
      validations: {
        greaterThanOrEqualTo0: (v) => v != null && v >= 0,
      },
    },
    priorCapacity: {
      label: '事前定員',
      type: 'integer',
      validations: {
        greaterThanOrEqualTo0: (v) => v != null && v >= 0,
        lessThanOrEqualToCapacity: (v, data, path) => {
          return (v || 0) <= get(data, path.replace(/\.\w+$/, '.capacity'));
        },
      },
    },
    lectureType: {
      label: '種別',
      type: 'select',
      options: lectureTypes.map(_ => ({ label: _.name, value: _.id })),
      width: 300,
    },
  };
};

const sendLaterQuestionInstructionEmail = functions.httpsCallable('sendLaterQuestionInstructionEmail');
const sendRemindEventEmail = functions.httpsCallable('sendRemindEventEmail');

function FrameForm(props) {
  const { values, lecture, lectureTypes, onChange, staffs } = props;
  const statedFields = useFormState(values, frameFields({ lecture, lectureTypes, staffs }), values);
  const [pics, { set: setPics, updateAt: updatePicAt }] = useList(
    (values.pics || []).map((_) => ({ ..._, originalValues: _ }))
  );
  useEffect(
    () => {
      onChange({ ...mapValues(statedFields, 'value'), pics: pics.map((_) => omit(_, ['originalValues'])) });
    },
    [...Object.values(statedFields).map((_) => _.value), pics.map(JSON.stringify).join(',')]
  );

  return (
    <div className="d-flex flex-wrap gap-1">
      {entries(statedFields).map(([fieldName, fieldSetting]) => (
        <div key={fieldName} style={{ width: fieldSetting.width || 150 }}>
          <Field name={fieldName} {...fieldSetting} />
        </div>
      ))}
      <ListForm
        items={pics}
        initialItem={{ picId: null, staffRole: "" }}
        hideAddButton={pics.length >= 2}
        renderItem={(item, itemIndex) => {
          return (
            <div className="card p-3">
              <PicForm
                values={item}
                staffs={staffs}
                onChange={(_) => updatePicAt(itemIndex, { ...item, ..._ })}
              />
            </div>
          );
        }}
        onChange={(items) => {
          setPics(items);
        }}
      />
    </div>
  );
}

function PicForm(props) {
  const { values, staffs, onChange } = props;
  const statedFields = useFormState(values, picFields({ staffs }), values);
  useEffect(
    () => {
      onChange(mapValues(statedFields, 'value'));
    },
    Object.values(statedFields).map((_) => _.value)
  );

  return (
    <div className="d-flex flex-wrap gap-1">
      {entries(statedFields).map(([fieldName, fieldSetting]) => (
        <div key={fieldName} style={{ width: fieldSetting.width || 100 }}>
          <Field name={fieldName} {...fieldSetting} />
        </div>
      ))}
    </div>
  );
}

export default function LectureForm(props) {
  const { eventId, values, questions, laterQuestions, undeletableFrame = false, surveys, eventProductTypes, lectureTypes, staffs } = props;
  const statedFields = useFormState(values, fields({ surveys, questions, laterQuestions, eventProductTypes, lectureTypes, }));
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value'), frames: frames.map((_) => omit(_, ['originalValues'])) });
  };
  const [frames, { set: setFrames, updateAt: updateFrameAt }] = useList(
    (values.frames || []).map((_) => ({ ..._, originalValues: _ }))
  );
  const [prevFrame] = frames.slice(-2, -1);
  const [lastFrame] = frames.slice(-1);
  const onAddFrame = () => {
    const minutes = prevFrame != null ? differenceInMinutes(prevFrame.endAt, prevFrame.startAt) : 20;
    const newStartAt = prevFrame != null ? prevFrame.endAt : new Date(1970, 0, 1, 9);
    const newEndAt = addMinutes(newStartAt, minutes);
    const newCapacity = prevFrame != null ? prevFrame.capacity : 3;
    const newPriorCapacity = prevFrame != null ? prevFrame.priorCapacity : 3;
    updateFrameAt(frames.length - 1, {
      startAt: newStartAt,
      endAt: newEndAt,
      capacity: newCapacity,
      priorCapacity: newPriorCapacity,
    });
  };
  useEffect(() => {
    if (!lastFrame || (lastFrame.startAt && lastFrame.endAt)) return;
    onAddFrame();
  }, [frames.length]);

  const onClickSendLaterQuestionInstructionEmail = async ({ displayName, email }) => {
    try {
      await sendLaterQuestionInstructionEmail({
        eventId,
        lectureId: values.id,
        displayName,
        email,
      })
      toast.success('送信しました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  const onClickSendRemindEventEmail = async ({ displayName, email }) => {
    try {
      await sendRemindEventEmail({
        eventId,
        lectureId: values.id,
        displayName,
        email,
      })
      toast.success('送信しました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <div>
        {entries(statedFields).map(([fieldName, fieldSetting]) => (
          <Field key={fieldName} name={fieldName} {...fieldSetting} />
        ))}
        <div className="d-flex justify-content-end mb-3 gap-2 flex-wrap text-nowrap">
          <ModalButton
            size="sm"
            className="mr-1"
            Modal={ModelFormModal}
            disabled={!values?.laterSurveyId}
            modalProps={{
              title: '事後アンケートメールのテスト送信',
              submitLabel: '送信',
              fields: {
                displayName: { type: 'string', label: '名前', },
                email: { type: 'string', label: 'メールアドレス' },
              },
              onSubmit: onClickSendLaterQuestionInstructionEmail,
            }}>
              <span className="fas fa-paper-plane mr-1" />
              事後アンケートメールのテスト送信
          </ModalButton>
          <ModalButton
            size="sm"
            className="mr-1"
            Modal={ModelFormModal}
            disabled={!values.id}
            modalProps={{
              title: 'イベントリマインドメールのテスト送信',
              submitLabel: '送信',
              fields: {
                displayName: { type: 'string', label: '名前', },
                email: { type: 'string', label: 'メールアドレス' },
              },
              onSubmit: onClickSendRemindEventEmail,
            }}>
              <span className="fas fa-paper-plane mr-1" />
              イベントリマインドメールのテスト送信
          </ModalButton>
        </div>
        <label>時間枠</label>
        <div className="alert alert-warning">
          ※お客様申込後、枠の時間、順序変更、追加できません。<br />
          追加の可能性がある場合、事前に「定員０」で登録してください。
        </div>
        <ListForm
          deleteItemDisabled={(item) => undeletableFrame && item.originalValues != null}
          items={frames}
          renderItem={(item, itemIndex) => {
            return (
              <div className="card p-3">
                <FrameForm
                  values={item}
                  lecture={mapValues(statedFields, 'value')}
                  lectureTypes={lectureTypes}
                  staffs={staffs}
                  onChange={(_) => updateFrameAt(itemIndex, { ...item, ..._ })}
                />
              </div>
            );
          }}
          onChange={(items) => {
            setFrames(items);
          }}
        />
      </div>
      <div className="d-flex mt-5">
        <Button className="cancel flex-fill" color="secondary" tag={TenantLink} to={`/admin/events/${eventId}`}>
          Back
        </Button>
        <Button
          className="save flex-fill ml-2"
          type="submit"
          color="primary"
          onClick={onSubmit}
          disabled={isUnsubmittable}
        >
          保存
        </Button>
      </div>
    </Form>
  );
}
