const { mapValues, groupBy, sumBy, isEmpty, } = require('lodash');
const { format: formatDate, } = require('date-fns');

const computeLeftQuantities = (purchaseOrders, containers) => {
  const containerItemsGroupedByPoId = groupBy(containers.flatMap(c => c.items.map(_ => ({ ..._, container: c, }))), 'purchaseOrderId');
  const computedPurchaseOrders = purchaseOrders.map((purchaseOrder) => {
    const computedItems = purchaseOrder.items.map((item) => {
      const containerItems = (containerItemsGroupedByPoId[purchaseOrder.id] || []).filter(_ => _.productId === item.productId);
      return {
        ...item,
        containerItems,
        leftQuantity: item.quantity - sumBy(containerItems, 'quantity'),
      };
    });
    return {
      ...purchaseOrder,
      computedItems,
    };
  });
  return computedPurchaseOrders;
};

const computeSchedule = (purchaseOrders, containers) => {
  const nullDate = new Date(1900, 0, 1);
  const purchaseOrderItemsGroupedByProductId = groupBy((
    purchaseOrders.map(_ => ({ ..._, manufactureMonth: formatDate(_.scheduledManufactureDate?.toDate() || nullDate, 'yyyyMM'), }))
  ).flatMap(p => p.items.map(_ => ({ ..._, purchaseOrder: p }))), 'productId');
  const containerItemsGroupedByProductId = groupBy((
    containers.map(_ => ({ ..._, arrivalMonth: formatDate(_.arrivalDate.toDate(), 'yyyyMM'), }))
  ).flatMap(c => c.items.map(_ => ({ ..._, container: c }))), 'productId');
  return mapValues(purchaseOrderItemsGroupedByProductId, (purchaseOrderItems, productId) => {
    const containerItems = containerItemsGroupedByProductId[productId] || [];
    const manufacture = mapValues(
      groupBy(purchaseOrderItems, 'purchaseOrder.manufactureMonth'),
      _ => sumBy(_, 'quantity')
    );
    const arrival = mapValues(
      groupBy(containerItems, 'container.arrivalMonth'),
      _ => sumBy(_, 'quantity'),
    );
    const transferingCount = (date) => {
      return sumBy(containerItems.filter(_ => _.container.departureDate.toDate() <= date && _.container.arrivalDate.toDate() > date), 'quantity');
    };
    const departureCount = (date) => {
      return sumBy(containerItems.filter(_ => _.container.departureDate.toDate() <= date), 'quantity');
    };
    const scheduledAbroadInventoryCount = (date) => {
      const scheduledManufactureCount = sumBy(purchaseOrderItems.filter(_ => _.purchaseOrder.scheduledManufactureDate?.toDate() <= date), 'quantity');
      return scheduledManufactureCount - departureCount(date);
    };
    const abroadInventoryCount = (date) => {
      const manufactureCount = sumBy(purchaseOrderItems.filter(_ => _.purchaseOrder.completedOn?.toDate() <= date), 'quantity');
      return manufactureCount - departureCount(date);
    };
    return {
      manufacture,
      arrival,
      transferingCount,
      scheduledAbroadInventoryCount,
      abroadInventoryCount,
    };
  });
};

module.exports = {
  fields: () => {
    return {
      poNumber: {
        label: 'PO No.',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      orderedOn: {
        label: '発注日',
        type: 'date',
        validations: {
          required: v => v != null,
        },
      },
      scheduledManufactureDate: {
        label: '製造予定日',
        type: 'date',
      },
      completedOn: {
        label: '完成日',
        type: 'date',
      },
      requestEtd: {
        label: '工場出荷希望日',
        type: 'date',
      },
      info: {
        label: 'Request',
        type: 'text',
        rows: 5,
      },
      note: {
        label: '社内メモ',
        type: 'text',
        rows: 5,
      },
    };
  },
  itemFields: ({ products, }) => {
    return {
      productId: {
        label: '商品',
        type: 'select',
        options: products.map(_ => ({ label: _.code, value: _.id })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      quantity: {
        label: '数量',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        showsTextInput: true,
      },
    };
  },
  computeLeftQuantities,
  computeSchedule,
};
