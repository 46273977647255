import React from 'react';
import { sortBy, keyBy, countBy } from 'lodash';
import { addDays, } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AdminPage from '../hocs/AdminPage';
import { statuses as troubleInquiryStatuses } from '../../shared/models/troubleInquiry';
import { statuses as inquiryStatuses } from '../../shared/models/inquiry';
import { statuses as methodInquiryStatuses } from '../../shared/models/methodInquiry';
import { statuses as surveyAnswerStatuses } from '../../shared/models/surveyAnswer';
import { getProductsWithInventory } from '../../shared/models/product';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const productsRef = db.collection('products');
const tmpOrdersRef = db.collectionGroup('tmpOrders');
const troubleInquiriesRef = db.collection('troubleInquiries');
const inquiriesRef = db.collection('inquiries');
const methodInquiriesRef = db.collection('methodInquiries');
const surveyAnswersRef = db.collection('surveyAnswers');
const ordersRef = db.collection('orders');

export default AdminPage(function AdminRoot(props) {
  const { tenant } = props;
  const products = useCollectionSubscriptionInTenant(productsRef);
  const productsById = keyBy(products, 'id');
  const envelopeProducts = useCollectionSubscriptionInTenant(db.collection('envelopeProducts'));
  const initialTmpOrders = useCollectionSubscriptionInTenant(tmpOrdersRef.where('status', '==', 'initial'));
  const receivingPlans = useCollectionSubscriptionInTenant(db.collection('receivingPlans').where('status', '==', 'initial'));
  const filteredReceivingPlans = sortBy(receivingPlans, (_) => _.date.toDate()).filter(
    (_) => addDays(_.date.toDate(), 1) > new Date()
  );
  const computedProducts = getProductsWithInventory(tenant, products, [], [], [], filteredReceivingPlans, initialTmpOrders).map(({ normalInventory, tmpOrderQuantity, alertInventory, receivingQuantity, }) => {
    const shouldAlert = normalInventory - tmpOrderQuantity < alertInventory;
    const hasEnoughReceiving = normalInventory - tmpOrderQuantity + receivingQuantity >= alertInventory;
    return {
      shouldAlert,
      hasEnoughReceiving,
    };
  });
  const alertEnvelopeProducts = envelopeProducts.filter(
    (_) => (productsById[_.id]?.normalInventory ?? 0) < (_.alertInventory ?? 0)
  );
  const troubleInquiries = useCollectionSubscriptionInTenant(
    troubleInquiriesRef.where('status', 'in', ['initial', 'supporting', 'awaiting'])
  );
  const inquiries = useCollectionSubscriptionInTenant(
    inquiriesRef.where('status', 'in', ['initial', 'supporting', 'awaiting'])
  );
  const methodInquiries = useCollectionSubscriptionInTenant(
    methodInquiriesRef.where('name', '!=', null).where('status', 'in', ['initial', 'supporting', 'awaiting'])
  );
  const surveyAnswers = useCollectionSubscriptionInTenant(
    surveyAnswersRef.where('status', 'in', ['initial', 'supporting', 'awaiting', 'supportRequired'])
  );
  const troubleInquiryCounts = countBy(troubleInquiries, 'status');
  const inquiryCounts = countBy(inquiries, 'status');
  const methodInquiriesCounts = countBy(methodInquiries, 'status');
  const surveyAnswersCounts = countBy(surveyAnswers, 'status');
  const approvalRequiredInquiries = inquiries.filter(
    ({ approvalStatus, approvalRequired, approvedAt }) => approvalRequired && !approvedAt && approvalStatus === 'requested'
  );
  const approvalRequiredTroubleInquiries = troubleInquiries.filter(
    ({ approvalStatus, approvalRequired, approvedAt }) => approvalRequired && !approvedAt && approvalStatus === 'requested'
  );
  const noReferrerOrderForShopCounts = useCollectionSubscriptionInTenant(ordersRef.where('referrerKey', '==', null).where('forShop', '==', true));
  const ordersHavingSimilarOrders = useCollectionSubscriptionInTenant(ordersRef.where('shouldStopBecauseHaveSimilarOrders', '==', true).where('cammacsStatus', '==', 'initial'));
  const ordersHavingMultiBody = useCollectionSubscriptionInTenant(ordersRef.where('shouldStopBecauseHaveMultiBodyOrders', '==', true).where('cammacsStatus', '==', 'initial'));
  const ordersNotCheckedForCoupons = useCollectionSubscriptionInTenant(ordersRef.where('couponId', '!=', null).where('couponCheckedAt', '==', null));

  return (
    <div>
      <div className="admin-events container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>管理画面トップ</h4>
        </div>
        <div className="d-flex flex-wrap justify-content-around align-items-start">
          <div className="card mb-4" style={{ minWidth: 450 }}>
            <div className="card-header">管理メニュー</div>
            <div className="card-body">
              <ul className="nav flex-column pb-2">
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/orders">
                    注文管理
                    {ordersNotCheckedForCoupons.length > 0 && (
                      <span className={`ml-1 badge badge-warning`}>
                        {numeral(ordersNotCheckedForCoupons.length).format()}
                      </span>
                    )}
                    {noReferrerOrderForShopCounts.length > 0 && (
                      <span className={`ml-1 badge badge-danger`}>
                        リ{numeral(noReferrerOrderForShopCounts.length).format()}
                      </span>
                    )}
                    {ordersHavingSimilarOrders.length > 0 && (
                      <span className={`ml-1 badge badge-danger`}>
                        同{numeral(ordersHavingSimilarOrders.length).format()}
                      </span>
                    )}
                    {ordersHavingMultiBody.length > 0 && (
                      <span className={`ml-1 badge badge-danger`}>
                        複{numeral(ordersHavingMultiBody.length).format()}
                      </span>
                    )}
                  </TenantLink>
                  <TenantLink className="nav-link" to="/admin/contentOrders">
                    コンテンツ注文管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/inquiries">
                    お問合せ管理
                    {['initial', 'supporting']
                      .filter((_) => inquiryCounts[_])
                      .map((status) => (
                        <span className={`ml-1 badge badge-${inquiryStatuses[status].color}`}>
                          {numeral(inquiryCounts[status]).format()}
                        </span>
                      ))}
                    {approvalRequiredInquiries.length > 0 && (
                      <span className={`ml-1 badge badge-primary`}>
                        {numeral(approvalRequiredInquiries.length).format()}
                      </span>
                    )}
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/troubleInquiries">
                    不具合組立問合せ管理
                    {['initial', 'supporting']
                      .filter((_) => troubleInquiryCounts[_])
                      .map((status) => (
                        <span className={`ml-1 badge badge-${troubleInquiryStatuses[status].color}`}>
                          {numeral(troubleInquiryCounts[status]).format()}
                        </span>
                      ))}
                    {approvalRequiredTroubleInquiries.length > 0 && (
                      <span className={`ml-1 badge badge-primary`}>
                        {numeral(approvalRequiredTroubleInquiries.length).format()}
                      </span>
                    )}
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/methodInquiries">
                    乗り方お問合せ管理
                    {['initial', 'supporting']
                      .filter((_) => methodInquiriesCounts[_])
                      .map((status) => (
                        <span className={`ml-1 badge badge-${methodInquiryStatuses[status].color}`}>
                          {numeral(methodInquiriesCounts[status]).format()}
                        </span>
                      ))}
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/users">
                    アカウント管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/userTagRules">
                    ユーザータグルール管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/multiOrdersUsers">
                    複数注文アカウント管理
                  </TenantLink>
                </li>
              </ul>
              <ul className="nav flex-column py-2">
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/events">
                    イベント管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/eventProductTypes">
                    イベント商品種別管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/lectureTypes">
                    レクチャー種別管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/places">
                    開催場所管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/questions">
                    アンケート管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/surveyGroups">
                    アンケートグループ管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/surveys">
                    アンケートページ管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/surveyAnswers">
                    アンケートページ回答管理
                    {['initial', 'supporting', 'supportRequired']
                      .filter((_) => surveyAnswersCounts[_])
                      .map((status) => (
                        <span className={`ml-1 badge badge-${surveyAnswerStatuses[status].color}`}>
                          {numeral(surveyAnswersCounts[status]).format()}
                        </span>
                      ))}
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/userVoices">
                    ユーザーズボイス管理
                  </TenantLink>
                </li>
              </ul>
              <ul className="nav flex-column py-2">
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/inventories">
                    在庫管理
                  </TenantLink>
                </li>
                {
                  !tenant.useWms && (
                    <li className="nav-item">
                      <TenantLink className="nav-link" to="/admin/receivingPlans">
                        入荷予定管理
                      </TenantLink>
                    </li>
                  )
                }
                {
                  tenant.useWms && (
                    <li className="nav-item">
                      <TenantLink className="nav-link" to="/admin/receivings">
                        入荷管理
                      </TenantLink>
                    </li>
                  )
                }
                {
                  tenant.useWms && (
                    <li className="nav-item">
                      <TenantLink className="nav-link" to="/admin/shippings">
                        出荷管理
                      </TenantLink>
                    </li>
                  )
                }
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/productTypes">
                    商品種別管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/productTags">
                    商品タグ管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/products">
                    商品管理
                    {(_ => _.length > 0 && (
                      <span className="ml-1 badge badge-danger">{_.length}</span>
                    ))(computedProducts.filter(_ => _.shouldAlert && !_.hasEnoughReceiving))}
                    {(_ => _.length > 0 && (
                      <span className="ml-1 badge badge-warning">{_.length}</span>
                    ))(computedProducts.filter(_ => _.shouldAlert && _.hasEnoughReceiving))}
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/productsAgents">
                    商品 × 代理店管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/envelopeProducts">
                    郵送物管理
                    {alertEnvelopeProducts.length > 0 && (
                      <span className="ml-1 badge badge-danger">{alertEnvelopeProducts.length}</span>
                    )}
                  </TenantLink>
                </li>
              </ul>
              <ul className="nav flex-column py-2">
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/couponTemplates">
                    優待テンプレート管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/coupons">
                    優待管理
                  </TenantLink>
                </li>
              </ul>
              <ul className="nav flex-column py-2">
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/agents">
                    代理店管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/qrUrls">
                    紹介QRコード遷移先管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/contentProducts">
                    コンテンツ商品
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/contentProductsAgents">
                    コンテンツ商品 × 代理店管理
                  </TenantLink>
                </li>
              </ul>
              <ul className="nav flex-column py-2">
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/cartSettings">
                    カート設定
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/emailSettings">
                    メール設定
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/textSettings">
                    テキスト管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/inquiryTypes">
                    問合せ種別管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/area">
                    エリア管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/customerJourneys">
                    カスタマージャーニー
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/magazines">
                    ステップSMS配信管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/smsDeliveries">
                    一括SMS配信
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/envelopeSchedules">
                    郵便スケジュール管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/envelopeDeliveries">
                    一括郵便発送
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/contacts">
                    コンタクトリスト
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/contents">
                    コンテンツ管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/shortUrls">
                    短縮URL管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/initialTags">
                    初期化タグ管理
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/auditLogs">
                    操作ログ
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/paceMakerSettings">
                    ペースメーカー設定
                  </TenantLink>
                </li>
                <li className='nav-item'>
                  <TenantLink className='nav-link' to='/admin/exportRequests'>
                    エクスポート履歴
                  </TenantLink>
                </li>
                <li className='nav-item'>
                  <TenantLink className='nav-link' to='/admin/accessLogs'>
                    アクセスログ集計
                  </TenantLink>
                </li>
                <li className='nav-item'>
                  <TenantLink className='nav-link' to='/admin/locales'>
                    言語設定
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/admin/settings">
                    その他設定
                  </TenantLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="card" style={{ minWidth: 450 }}>
            <div className="card-header">ユーザーページ リンク</div>
            <div className="card-body">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/events">
                    イベント一覧
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/products">
                    商品一覧（カート入り口）
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/parts">
                    パーツ一覧（カート入り口）
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/troubleInquiry">
                    不具合組立問合せ
                  </TenantLink>
                </li>
                <li className="nav-item">
                  <TenantLink className="nav-link" to="/contacts">
                    お問合せトップ
                  </TenantLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
