import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { sortBy, pick, get, isEmpty, keyBy, debounce } from 'lodash';
import { useToggle, useCounter, } from 'react-use';

import firebase, { functions, serverTimestamp } from '../../firebase';
import useAppTitle from '../hooks/useAppTitle';
import useLocale from '../hooks/useLocale';
import EntryForm from '../forms/EntryForm';
import EntryConfirmForm from '../forms/EntryConfirmForm';
import TenantUserPage from '../hocs/TenantUserPage';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import usePrevious from '../hooks/usePrevious';
import useQueryParams from '../hooks/useQueryParams';
import useAnyEventAction from '../hooks/useAnyEventAction';
import useTenant from '../hooks/useTenant';

const db = firebase.firestore();
const eventsRef = db.collection('events');
const questionsRef = db.collection('questions');
const surveysRef = db.collection('surveys');
const storageRef = firebase.storage().ref();
const createEntry = functions.httpsCallable('createEntry');

export default TenantUserPage(function Entry(props) {
  const {
    user,
    history,
    match: {
      params: { eventId, entryId, tenantPath },
    },
  } = props;
  const [version, { inc: updateVersion, }] = useCounter();
  const { key: theDayKey, forceKey, } = useQueryParams();
  const [showsConfirm, toggleConfirm] = useToggle();
  const [values, setValues] = useState({ frames: [], answer: [] });
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const locale = useDocumentSubscription(event && db.collection('locales').doc([tenantPath, event.lang || 'ja'].join('__')), [event]);
  const { translate: _translate, } = useLocale();
  const translate = _ => _translate(_, locale);
  const entry = useDocumentSubscription(entryId && eventRef.collection('entries').doc(entryId), [eventId, entryId], { onError: (({ setItem }) => setItem(null)) });
  const prevEntry = usePrevious(entry);
  const lectures = useCollectionSubscription(eventRef.collection('lectures').orderBy('date'), [eventId], {
    initialItems: null,
  });
  const questions = useCollectionSubscriptionInTenant(questionsRef, [], { initialItems: null });
  const sortedQuestions = sortBy(questions, (_) => _.createdAt.toDate());
  const surveys = useCollectionSubscriptionInTenant(surveysRef, [], { initialItems: null });
  const sortedSurveys = sortBy(surveys, (_) => _.createdAt.toDate());
  const questionsById = keyBy(questions, 'id');
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), (_) =>
    _.createdAt.toDate()
  );
  const lectureTypesById = keyBy(lectureTypes, 'id');
  const tenant = useTenant();
  const emailSetting = useDocumentSubscription(db.collection('settings').doc([tenant?.id, 'email'].join('__')));
  const isTestEntry = (emailSetting?.eventTestMailAddress?.split(',') || []).includes(user?.email);

  const createSurveyAnswers = async (surveyId, answers) => {
    const survey = surveys.find((_) => _.id === surveyId);
    if (!survey) return;

    const ref = db.collection('surveyAnswers').doc();
    return {
      id: ref.id,
      tenantId: tenantPath,
      answers: await Object.entries(answers).reduce(async (x, [k, v]) => {
        const prevs = await x;

        const question = questionsById[k];
        if (!survey.questionRows.map(_ => _.questionId).includes(question.id)) return prevs;
        return {
          ...prevs,
          [k]:
            question?.type === 'imageFile' && !isEmpty(v)
              ? await Promise.all(
                  v.map(async (file) => {
                    const fileRef = storageRef.child(`surveyAnswers/${ref.id}/${k}/${new Date().toISOString()}/${file.name}`);
                    await fileRef.put(file, { contentType: file.type });
                    return {
                      ...pick(file, ['name', 'type']),
                      url: await fileRef.getDownloadURL(),
                    };
                  })
                )
              : v,
        };
      }, Promise.resolve({})),
      surveyId,
      tag: null,
    };
  };

  const onSubmit = async (values) => {
    setValues(values);
    toggleConfirm();
  };
  const onSubmitConfirm = async (stripe, elements, values) => {
    const { amount, stripePaymentIntent, } = values;
    try {
      const { amount, answers, frames, surveyIds } = values;
      if (amount > 0 && !isTestEntry) {
        const { error } = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
          confirmParams: {
            payment_method_data: {
              billing_details: {
                address: {
                  country: "JP"
                }
              }
            }
          },
        });
        if(error) throw error;
      }

      const surveyAnswers = (await Promise.all(surveyIds.map((_) => createSurveyAnswers(_, answers)))).filter(_ => _);
      await Promise.all(frames.filter(_ => _.isRegisterChildProfile).map(_ => user.ref.update({ children: [...(user.children || []), pick(_, ['name', 'birthday', 'gender', 'vehicleExperiences'])] })))
      const {
        createdBy: { uid, email },
      } = entry;
      const { data } = await createEntry({
        eventId,
        entryId,
        entryValues: {
          amount,
          answers,
          frames: frames.map((_) => pick(_, ['lectureId', 'frameIndex', 'name', 'birthday'])),
        },
        stripePaymentIntentId: stripePaymentIntent?.id,
        theDayKey: theDayKey ?? '',
        forceKey,
        userId: uid,
        email,
        surveyAnswers,
        isTestEntry
      });
      if (get(data, 'error') != null) throw new Error(data.error.message);
      history.push(`/mypage/entries`);
      toast.success(translate('お申し込み完了しました。ありがとうございます。'));
    } catch (e) {
      toast.error(translate(e.message || '失敗しました'));
      console.error(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showsConfirm]);
  useAppTitle(event?.name + ' 参加するお子様');

  useAnyEventAction(debounce(async () => {
    if (theDayKey && entry && entry.isTemporary) {
      await entry.ref.update({ lastActedAt: serverTimestamp() });
    }
  }, 1000, { maxWait: 1000 * 10 }))

  if (entry === null) {
    toast.error(translate('時間切れになりました。\n恐れ入りますが最初からお申し込みください。'));
    history.push(`/events/${eventId}/entries/new`);
    return null;
  }
  if (!prevEntry && entry && !entry.isTemporary) {
    toast.error(translate('お申込みは完了しています。'));
    history.push('/mypage/entries');
    return null;
  }

  return (
    <div className="entry position-relative">
      {entry &&
        lectures &&
        questions &&
        surveys &&
        (!showsConfirm ? (
          <EntryForm
            event={event}
            translate={translate}
            eventId={eventId}
            values={entry}
            lectures={lectures}
            lectureTypesById={lectureTypesById}
            questions={sortedQuestions}
            surveys={sortedSurveys}
            onSubmit={onSubmit}
          />
        ) : (
          <EntryConfirmForm
            translate={translate}
            eventId={eventId}
            event={event}
            values={values}
            lectures={lectures}
            lectureTypesById={lectureTypesById}
            questions={sortedQuestions}
            onSubmit={onSubmitConfirm}
            onClickBack={toggleConfirm}
            surveys={sortedSurveys}
            isTestEntry={isTestEntry}
            version={version}
          />
        ))}
    </div>
  );
});
