import React from 'react';
import { orderBy, groupBy, sortBy, get, keyBy, uniq, omit, omitBy, pick, isUndefined } from 'lodash';
import { format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';
import dedent from 'dedent';
import numeral from 'numeral';
import nl2br from 'nl2br';
import { Button } from 'reactstrap';

import firebase, { functions } from '../../firebase';
import { canCreateSmsDelivery } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useTenant from '../hooks/useTenant';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import { userConditionText } from '../../shared/util';
import { statuses, fields, orderTypes, entryTypes, dateTypes } from '../../shared/models/smsDelivery';
import TenantLink from '../TenantLink';
import AddInTenantButton from '../AddInTenantButton';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import { deliveryMethods } from '../../shared/config';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';
import DeleteButton from '../DeleteButton';

const db = firebase.firestore();
const sendSms = functions.httpsCallable('sendSms');
const sendEmail = functions.httpsCallable('sendEmail');
const sendMobile = functions.httpsCallable('sendMobile');

export default AdminPage(function AdminSmsDeliveries(props) {
  const { user } = props;
  const tenant = useTenant();
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts
    .map((_) => ({
      ..._,
      label: `${_.isHidden ? '[非表示] ' : ''}[${_.code}] ${_.name}`,
    }));
  const productsById = keyBy(products, 'id');
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), (_) =>
    _.createdAt.toDate()
  );
  const productTypesById = keyBy(productTypes, 'id');
  const lectures = useCollectionSubscriptionInTenant(db.collectionGroup('lectures').orderBy('date'));
  const lecturesGroupedByEventId = groupBy(lectures, (_) => _.ref.parent.parent.id);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const eventsById = keyBy(events, 'id');
  const sortedEvents = sortBy(events, (event) => {
    const date = get(lecturesGroupedByEventId, `${event.id}.0.date`);
    return date && date.toDate();
  });
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const areaGroups = uniq(Object.values(areaSetting?.data || {}).map((_) => _.group));
  const smsDeliveries = orderBy(
    useCollectionSubscriptionInTenant(db.collection('smsDeliveries')),
    (_) => _.createdAt.toDate(),
    'desc'
  );

  return (
    <div>
      <div className="admin-sms-deliveries container-fluid py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>一括SMS配信</h4>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <AddInTenantButton
                  label="SMS配信する"
                  processValues={(_) => ({ ..._, status: 'create' })}
                  itemRef={db.collection('smsDeliveries').doc()}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: 'SMS配信',
                    fields: fields({
                      products: selectableProducts,
                      productTypes,
                      events: sortedEvents,
                      userTags,
                      areaGroups,
                      useSms: tenant.useSms,
                      useMobile: tenant.useMobile
                    }),
                    hasSpin: true,
                    submitLabel: '配信条件を保存する',
                  }}
                  disabled={!canCreateSmsDelivery(user)}
                />
              </div>
              <hr className="my-5" />
              <h5>配信履歴</h5>
              <div>
                {smsDeliveries.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center text-nowrap">
                      <tr>
                        <th>配信日時</th>
                        <th>ステータス</th>
                        <th>配信方式</th>
                        <th>注文条件</th>
                        <th>イベント参加条件</th>
                        <th>アカウント条件</th>
                        <th>日付範囲</th>
                        <th>対象件数</th>
                        <th>Eメール件名</th>
                        <th>本文</th>
                        <th>作成日時</th>
                        <th>メモ</th>
                        <th style={{ minWidth: 300 }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {smsDeliveries.map((smsDelivery) => (
                        <SmsDeliveryRow
                          key={smsDelivery.id}
                          user={user}
                          productTypesById={productTypesById}
                          productsById={productsById}
                          eventsById={eventsById}
                          selectableProducts={selectableProducts}
                          productTypes={productTypes}
                          sortedEvents={sortedEvents}
                          userTags={userTags}
                          areaGroups={areaGroups}
                          smsDelivery={smsDelivery}
                        />
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>No Data</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const SmsDeliveryRow = (props) => {
  const tenant = useTenant();
  const {
    user,
    productTypesById,
    productsById,
    eventsById,
    selectableProducts,
    productTypes,
    sortedEvents,
    userTags,
    areaGroups,
    smsDelivery,
  } = props;
  const {
    id,
    ref,
    name,
    orderType = 'any',
    conditionProductTypeIds,
    conditionProductIds,
    entryType = 'any',
    conditionEventIds,
    dateType,
    emailSubject,
    emailBody,
    smsBody,
    status,
    createdAt,
    completedAt,
    deliveryMethod,
    note,
    mobileSubject,
    mobileMessage,
    mobileNotificationType,
    mobileNotificationContentType,
    mobileUrl,
    mobileHtml,
    fixUntil,
    mobileTypeDisplayName
  } = smsDelivery;
  const content = { text: mobileMessage, url: mobileUrl, html: mobileHtml }[mobileNotificationContentType] || '';
  const deliveryCandidates = useCollectionSubscriptionInTenant(db.collection(`smsDeliveries/${id}/deliveryCandidates`));
  const emailSetting = useDocumentSubscription(db.collection('settings').doc([tenant?.id, 'email'].join('__')));
  const { label: statusLabel, color } = statuses[status] || {};
  const onClickSendSms = async ({ displayName, phone, email }) => {
    try {
      if (deliveryMethod === 'email') {
        await sendEmail({
          from_name: tenant.name,
          to: [
            {
              email,
              name: displayName,
              type: 'to',
            },
          ],
          subject: `${emailSubject}`,
          text: dedent`
            ${emailBody.replace(/\{\{name\}\}/g, displayName)}
            <br />
            ${nl2br(emailSetting?.bodyFooter)}
          `,
        });
      } else if (deliveryMethod === 'sms') {
        await sendSms({ targets: [{ displayName, phone }], text: smsBody });
      } else {
        await sendMobile({ targets: [{ email, displayName }], subject: mobileSubject, contentType: mobileNotificationContentType, content, type: mobileNotificationType, typeDisplayName: mobileTypeDisplayName, fixUntil: fixUntil?.toDate().toISOString() });
      }
      toast.success('送信しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const handleClickCopy = async () => {
    if (!window.confirm('配信条件をコピーしますか？')) return;
    try {
      await db.collection('smsDeliveries').add({
        ...omit(smsDelivery, ['id', 'ref', 'createdAt', 'addedBy']),
        status: 'copied',
        createdAt: new Date(),
        addedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
      });
      toast.success('コピーしました。');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const handleClickCreate = async () => {
    if (!window.confirm('配信リストを作成しますか？')) return;
    try {
      await db.collection('smsDeliveries').doc(id).update({
        status: 'create',
      });
      toast.success('リスト作成を開始しました。');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  return (
    <tr key={id} data-id={id}>
      <td style={{ whiteSpace: 'pre-line' }}>
        <TenantLink to={`/admin/smsDeliveries/${id}`}>
          {completedAt ? formatDate(completedAt.toDate(), 'yyyy/MM/dd HH:mm:ss') : '未配信'}
        </TenantLink>
      </td>
      <td>
        <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
      </td>
      <td>{deliveryMethod && deliveryMethods[deliveryMethod]?.label}</td>
      <td>
        {orderTypes[orderType]?.label}
        {orderType === 'ordered' && (
          <div>
            <div className="small text-muted">
              {conditionProductTypeIds?.map((_) => productTypesById[_]?.name).join(',')}
            </div>
            <div className="small text-muted">
              {conditionProductIds
                ?.map((_) => productsById[_])
                .map((_) => `[${_?.code}] ${_?.name}`)
                .join(',')}
            </div>
          </div>
        )}
      </td>
      <td>
        {entryTypes[entryType]?.label}
        {entryType === 'entried' && (
          <div className="small text-muted">{conditionEventIds?.map((_) => eventsById[_]?.name).join(',')}</div>
        )}
      </td>
      <td className="text-muted small">{userConditionText(smsDelivery)}</td>
      <td>
        <span>{dateTypes[dateType]?.text({ data: smsDelivery })}</span>
      </td>
      <td style={{ whiteSpace: 'pre-line' }} className="text-right">
        {numeral(deliveryCandidates.reduce((x, y) => x + y.deliveryCount, 0)).format('0,0')}
      </td>
      <td style={{ whiteSpace: 'pre-line' }}>{emailSubject || mobileSubject}</td>
      <td style={{ whiteSpace: 'pre-line' }}>
        <div style={{ maxWidth: 300, maxHeight: 200, overflow: 'hidden' }}>{emailBody || smsBody || content}</div>
      </td>
      <td style={{ whiteSpace: 'pre-line' }}>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
      <td style={{ whiteSpace: 'pre-line' }}>
        {note}
      </td>
      <td className="text-nowrap text-right">
        <ModalButton
          Modal={ModelFormModal}
          modalProps={{
            title: 'テスト送信',
            submitLabel: '送信',
            fields: {
              displayName: { type: 'string', label: '名前' },
              ...(['email', 'mobile'].includes(smsDelivery.deliveryMethod)
                ? { email: { type: 'string', label: 'メールアドレス' } }
                : { phone: { type: 'string', label: '電話番号' } }),
            },
            onSubmit: onClickSendSms,
          }}
        >
          <span className="fas fa-paper-plane mr-1" />
          テスト送信
        </ModalButton>
        {status === 'copied' ? (
          <Button className="ml-2" onClick={handleClickCreate} disabled={!canCreateSmsDelivery(user)}>
            <span className="fas fa-plus mr-1" />
            リスト作成
          </Button>
        ) : (
          <Button className="ml-2" onClick={handleClickCopy} disabled={!canCreateSmsDelivery(user)}>
            <span className="fas fa-copy mr-1" />
            コピー
          </Button>
        )}
        <EditButton
          itemRef={ref}
          className="ml-2"
          FormModal={ModelFormModal}
          formProps={{
            title: 'SMS配信',
            fields: fields({
              products: selectableProducts,
              productTypes,
              events: sortedEvents,
              userTags,
              areaGroups,
              useSms: tenant.useSms,
              useMobile: tenant.useMobile
            }),
            hasSpin: true,
            submitLabel: '配信条件を保存する',
          }}
          disabled={!canCreateSmsDelivery(user) || !['preview', 'copied'].includes(status)}
        />
        <DeleteButton
          className="ml-2"
          itemRef={smsDelivery.ref}
          disabled={!['preview', 'error', 'failed', 'copied'].includes(smsDelivery.status)}
        />
        <div>
          {['create', 'creating', 'copied'].includes(status) || (
            <TenantLink to={`/admin/smsDeliveries/${smsDelivery.id}/deliveryTargets`} target="_blank">
              配信対象
            </TenantLink>
          )}
        </div>
      </td>
    </tr>
  );
};
