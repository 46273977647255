import React, { Fragment, useState, } from 'react';
import { groupBy, sortBy, omitBy, isUndefined, isEmpty, pick, omit, orderBy, flatten, uniq, keyBy } from 'lodash';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { format as formatDate } from 'date-fns';

import firebase, { functions } from '../firebase';
import { fieldDisplayValue, ageDisplay } from '../shared/util';
import { fields, blockSmsFields, adminFields, commentFields } from '../shared/models/user';
import ModelFormModal from './modals/ModelFormModal';
import TenantLink from './TenantLink';
import PopoverButton from './PopoverButton';
import useFirebaseUser from './hooks/useFirebaseUser';
import useTenant from './hooks/useTenant';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useDocumentsFetch from './hooks/useDocumentsFetch';
import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from './AddInTenantButton';

const db = firebase.firestore();
const usersRef = db.collection('users');
const ordersRef = db.collection('orders');
const troubleInquiriesRef = db.collection('troubleInquiries');
const methodInquiriesRef = db.collection('methodInquiries');
const inquiriesRef = db.collection('inquiries');
const inquiryTypesRef = db.collection('inquiryTypes');
const entriesRef = db.collectionGroup('entries');
const productsRef = db.collection('products');
const userTagsRef = db.collection('userTags');
const activitiesRef = db.collection('activities');
const { entries } = Object;

export default function AdminUserLink(props) {
  const { operator, user, ...extraProps } = props;
  const tenant = useTenant();

  return (
    <div className="d-flex justify-content-start gap-2">
      <TenantLink to={`/admin/users/${user.uid}`} {...extraProps}>
        {user.displayName}
      </TenantLink>
      <div className="d-flex gap-1">
        <PopoverButton
          id={`${user.uid}-profile`}
          size="sm"
          color="link"
          className="p-0"
          popoverProps={{ placement: 'bottom', style: { maxWidth: 400, }, }}
          body={_ => <ProfileContent operator={operator} user={user} tenant={tenant} />}
        >
          <span className="fas fa-id-card" />
        </PopoverButton>
        <PopoverButton
          id={`${user.uid}-activities`}
          size="sm"
          color="link"
          className="p-0"
          popoverProps={{ placement: 'bottom', style: { maxWidth: 400, }, }}
          body={_ => <ActivitiesContent operator={operator} user={user} tenant={tenant} />}
        >
          <span className="fas fa-history" />
        </PopoverButton>
        <PopoverButton
          id={`${user.uid}-comments`}
          size="sm"
          color="link"
          className="p-0"
          popoverProps={{ placement: 'bottom', style: { maxWidth: 400, }, }}
          body={_ => <CommentsContent operator={operator} user={user} tenant={tenant} togglePopover={_.togglePopover} />}
        >
          <span className="fas fa-comment" />
        </PopoverButton>
      </div>
    </div>
  );
}

function ProfileContent (props) {
  const { user, tenant } = props;
  const tenantUser = useDocumentSubscription(tenant.ref.collection('tenantUsers').doc(user.uid), [user.uid]);
  const userTags = sortBy(useCollectionSubscriptionInTenant(db.collection('userTags')), (_) => _.createdAt.toDate());
  const magazineGroups = sortBy(useCollectionSubscriptionInTenant(db.collection('magazineGroups')), (_) =>
    _.createdAt.toDate()
  );
  const magazineGroupsById = keyBy(magazineGroups, 'id');

  return tenantUser != null && (
    <div className="overflow-auto" style={{ maxHeight: '50vh', }}>
      <div className="d-flex flex-column gap-4">
        {entries({
          ...omit(fields({ magazineGroups }), [
            'password',
            'currentPassword',
            'passwordConfirmation',
            'accept',
          ]),
          ...adminFields({ userTags }),
          // TODO: fieldSettingの型定義する
        }).map(([fieldName, fieldSetting]) => {
          const { label } = fieldSetting;
          const displayValue = fieldDisplayValue(tenantUser[fieldName], fieldSetting);
          return (
            <div key={fieldName}>
              <div className="small font-weight-bold">{label}</div>
              <div style={{ whiteSpace: 'pre-line' }}>
                {Array.isArray(displayValue) ? displayValue.join(', ') : displayValue}
              </div>
            </div>
          );
        })}
        <div>
          <div className="small font-weight-bold">SMS/メール配信を停止する</div>
          <div style={{ whiteSpace: 'pre-line' }}>
            {user[`blocksSms__${tenant.id}`] ? 'YES' : 'NO'}
          </div>
        </div>
        <div>
          <div className="small font-weight-bold">SMS/メール配信停止グループ</div>
          <div style={{ whiteSpace: 'pre-line' }}>
            {(user[`blocksMagazineGroupIds__${tenant.id}`] || []).map(_ => magazineGroupsById[_]?.name).filter(_ => _).join(', ')}
          </div>
        </div>
      </div>
    </div>
  );
}

function ActivitiesContent (props) {
  const { user, tenant } = props;
  const orders = useCollectionSubscriptionInTenant(user && ordersRef.where('createdBy.uid', '==', user.uid), [user]);
  const referredEmail = useDocumentSubscription(user && db.collection('referredEmails').doc(user.email), [user]);
  const referredEmailQrUrl = useDocumentSubscription(referredEmail && db.collection('qrUrls').doc(referredEmail.qrUrlId), [referredEmail]);
  const orderedProductIds = uniq(flatten(orders.map(({ orderItems }) => orderItems.map(({ productId }) => productId))));
  const orderedProducts = useDocumentsFetch(
    orderedProductIds.map((_) => productsRef.doc(_)),
    [orders]
  );
  const orderedProductsById = keyBy(orderedProducts, 'id');
  const envelopeProducts = useCollectionSubscriptionInTenant(db.collection('envelopeProducts'));
  const envelopeProductsById = keyBy(envelopeProducts, 'id');
  const troubleInquiries = useCollectionSubscriptionInTenant(
    user && troubleInquiriesRef.where('createdBy.uid', '==', user.uid),
    [user]
  );
  const methodInquiries = useCollectionSubscriptionInTenant(
    user && methodInquiriesRef.where('createdBy.uid', '==', user.uid),
    [user]
  );
  const inquiries = useCollectionSubscriptionInTenant(user && inquiriesRef.where('createdBy.uid', '==', user.uid), [
    user,
  ]);
  const inquiryTypes = useCollectionSubscriptionInTenant(inquiryTypesRef);
  const inquiryTypesById = keyBy(inquiryTypes, 'id');
  const eventEntries = useCollectionSubscriptionInTenant(user && entriesRef.where('createdBy.uid', '==', user.uid), [
    user,
  ]);
  const events = useDocumentsFetch(
    eventEntries.map(({ ref }) => ref?.parent.parent),
    [eventEntries]
  );
  const eventsById = keyBy(events, 'id');
  const surveyAnswers = useCollectionSubscriptionInTenant(
    user && db.collection('surveyAnswers').where('createdBy.uid', '==', user.uid),
    [user]
  );
  const surveys = useDocumentsFetch(
    surveyAnswers.map((_) => db.collection('surveys').doc(_.surveyId)),
    [surveyAnswers]
  );
  const surveysById = keyBy(surveys, 'id');
  const userTags = sortBy(useCollectionSubscriptionInTenant(db.collection('userTags')), (_) => _.createdAt.toDate());
  const deliveryHistories = useCollectionSubscriptionInTenant(
    user && db.collectionGroup('deliveryTargets').where('uid', '==', user.uid).where('status', '==', 'delivered').orderBy('createdAt'),
    [user]
  );
  const deliveryHistoryParents = useDocumentsFetch(
    deliveryHistories.map((_) => _.ref.parent.parent),
    [deliveryHistories]
  );
  const deliveryHistoryParentsByPath = keyBy(deliveryHistoryParents, 'ref.path');
  const magazineGroups = sortBy(useCollectionSubscriptionInTenant(db.collection('magazineGroups')), (_) =>
    _.createdAt.toDate()
  );
  const magazineGroupsById = keyBy(magazineGroups, 'id');
  const finishSupportActivities = useCollectionSubscriptionInTenant(user && activitiesRef.where('payload.uid', '==', user.uid).orderBy('createdAt'), [user]);
  const accessLogs = useCollectionSubscriptionInTenant(
    user && db.collection('accessLogs').where('createdBy.uid', '==', user.uid).limit(1),
    [user]
  );
  const activities = orderBy(
    [
      ...orders.map((order) => {
        const { id, createdAt, orderItems, isWish = false, } = order;
        const label = `商品購入${isWish ? ' (おねだり)' : ''}`;
        const description = `${orderItems.map(({ productId }) => orderedProductsById[productId]?.name).join(', ')}`;
        const queryParams = { field: ['id'], text: id };
        const path = `/admin/orders?${qs.stringify(queryParams)}`;

        return { id, createdAt, label, description, path };
      }),
      ...orders.filter(_ => _.cancelledAt != null).map((order) => {
        const { id, createdAt, orderItems, cancelledAt, } = order;
        const label = '商品購入キャンセル';
        const description = `${formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}購入分 (${orderItems.map(({ productId }) => orderedProductsById[productId]?.name).join(', ')})`;
        const queryParams = { field: ['id'], text: id };
        const path = `/admin/orders?${qs.stringify(queryParams)}`;

        return { id, createdAt: cancelledAt, label, description, path };
      }),
      ...troubleInquiries.map((troubleInquiry) => {
        const { id, createdAt } = troubleInquiry;
        const label = 'お問合せ';
        const description = '不具合・組立お問合せ';
        const path = `/admin/troubleInquiries/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...methodInquiries.map((methodInquiry) => {
        const { id, createdAt } = methodInquiry;
        const label = 'お問合せ';
        const description = '乗り方お問合せ';
        const path = `/admin/methodInquiries/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...inquiries.map((inquiry) => {
        const { id, createdAt, inquiryTypeId, isAdminCreated } = inquiry;
        const label = isAdminCreated ? 'お問合せ（管理者作成）' : 'お問合せ';
        const description = inquiryTypesById[inquiryTypeId]?.subject;
        const path = `/admin/inquiries/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...eventEntries.map((entry) => {
        const {
          id,
          createdAt,
          ref: {
            parent: {
              parent: { id: eventId },
            },
          },
        } = entry;
        const label = 'イベント申込';
        const description = eventsById[eventId]?.name;
        const path = `/admin/events/${eventId}/entries/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...eventEntries.filter(_ => _.cancelledAt != null).map((entry) => {
        const { id, ref, createdAt, cancelledAt, } = entry;
        const label = 'イベント申込キャンセル';
        const description = `${formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}申込み分 (${eventsById[ref.parent.parent.id]?.name})`;
        const path = `/admin/events/${ref.parent.parent.id}/entries/${id}`;

        return { id, createdAt: cancelledAt, label, description, path };
      }),
      ...surveyAnswers.map((surveyAnswer) => {
        const { id, surveyId, createdAt } = surveyAnswer;
        const label = 'アンケートページ回答';
        const description = surveysById[surveyId]?.title;
        const path = `/admin/surveyAnswers/${id}`;

        return { id, createdAt, label, description, path };
      }),
      ...deliveryHistories.map((deliveryHistory) => {
        const { id, ref, createdAt } = deliveryHistory;
        const deliveryHistoryParent = deliveryHistoryParentsByPath[ref.parent.parent.path];
        const { label, description } =
          {
            magazines: {
              label: 'SMS配信',
              description: deliveryHistoryParent?.body,
            },
            smsDeliveries: {
              label: 'SMS配信',
              description: deliveryHistoryParent?.emailSubject || deliveryHistoryParent?.body,
            },
            envelopeSchedules: {
              label: '郵便発送処理',
              description: (deliveryHistoryParent?.envelopeProductIds || [])
                .map((_) => envelopeProductsById[_]?.name || '')
                .join(', '),
            },
            envelopeDeliveries: {
              label: '郵便発送処理',
              description: (deliveryHistoryParent?.envelopeProductIds || [])
                .map((_) => envelopeProductsById[_]?.name || '')
                .join(', '),
            },
          }[ref.parent.parent.parent.id] || {};
        const path = `/admin/${ref.parent.parent.path}`;

        return { id, createdAt, label, description, path };
      }),
      ...finishSupportActivities.filter(_ => _.type === 'finishSupportOfInquiry').map((finishSupportActivity) => {
        const { id, createdBy, createdAt, payload } = finishSupportActivity;
        const label = '問合せ完了';
        const description = createdBy.displayName;
        const path = `/admin/inquiries/${payload.inquiryId}`;
        return { id, createdAt, label, description, path };
      }),
      ...finishSupportActivities.filter(_ => _.type === 'finishSupportOfTroubleInquiry').map((finishSupportActivity) => {
        const { id, createdBy, createdAt, payload } = finishSupportActivity;
        const label = '不具合問合せ完了';
        const description = createdBy.displayName;
        const path = `/admin/troubleInquiries/${payload.troubleInquiryId}`;
        return { id, createdAt, label, description, path };
      }),
      ...finishSupportActivities.filter(_ => _.type === 'checkCouponOfOrder').map((activity) => {
        const { id, createdBy, createdAt, payload } = activity;
        const label = '優待確認';
        const description = createdBy.displayName;
        const path = `/admin/orders/${payload.orderId}`;
        return { id, createdAt, label, description, path };
      }),
      ...finishSupportActivities.filter(_ => _.type === 'uncheckCouponOfOrder').map((activity) => {
        const { id, createdBy, createdAt, payload } = activity;
        const label = '優待確認解除';
        const description = createdBy.displayName;
        const path = `/admin/orders/${payload.orderId}`;
        return { id, createdAt, label, description, path };
      }),
      ...[referredEmail != null && {
        ...pick(referredEmail, ['id', 'createdAt']),
        label: '紹介QRコード遷移先へのリダイレクト前のメールアドレス入力',
        description: referredEmailQrUrl?.name,
        path: `/referralRedirect?${qs.stringify({ qrUrlId: referredEmailQrUrl?.id, redirectUrl: referredEmailQrUrl?.redirectUrl, referrerKey: referredEmail?.referrerKey })}`,
      }].filter(_ => _),
    ],
    ({ createdAt }) => createdAt.toDate(),
    'asc'
  );

  return (
    <div className="overflow-auto" style={{ maxHeight: '50vh', }}>
      <div className="d-flex flex-column gap-4">
        {activities.map((activity, index) => {
          const { createdAt, label, description, path } = activity;

          return (
            <div key={index}>
              <div className="d-flex gap-2">
                <div className="text-muted small">
                  {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                </div>
                <div className="font-weight-bold small">
                  <TenantLink to={path} target="_blank">
                    {label}
                  </TenantLink>
                </div>
              </div>
              <div>
                {description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function CommentsContent (props) {
  const { operator, user, tenant } = props;
  const { firebaseUser } = useFirebaseUser();
  const commentsRef = user.ref.collection('userComments');
  const comments = sortBy(useCollectionSubscriptionInTenant(commentsRef, [user]), (_) => _.createdAt.toDate());

  return (
    <div className="overflow-auto" style={{ maxHeight: '50vh', }}>
      <div className="mt-4 d-flex justify-content-end mb-3">
        <AddInTenantButton
          label="新規コメント"
          itemRef={commentsRef.doc()}
          processValues={(_) => ({
            ..._,
            createdBy: pick(operator, ['uid', 'email', 'displayName']),
          })}
          FormModal={ModelFormModal}
          formProps={{ title: '新規コメント', fields: commentFields }}
        />
      </div>
      <div>
        {comments.length > 0 ? (
          <div>
            {comments.map(({ id, body, createdAt, createdBy }) => {
              return (
                <div key={id} className="card p-3 mb-3">
                  <div className="small text-muted mb-1">
                    {createdBy?.displayName} {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                  </div>
                  <div style={{ whiteSpace: 'pre-line' }}>{body}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>コメントはまだありません</div>
        )}
      </div>
    </div>
  );
}
