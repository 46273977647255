import React, { useState, useMemo, } from 'react';
import { pick, sortBy, keyBy, isEmpty, uniq, omit, orderBy, get } from 'lodash';
import { Button, } from 'reactstrap';
import { format as formatDate, eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';

import firebase from '../../firebase';
import { fullPathWithParams } from '../../util';
import { adminFields, statuses } from '../../shared/models/surveyAnswer';
import { areaFromPostalCode } from '../../shared/models/setting';
import { auditData } from '../../shared/models/user';
import { defaultGroups } from '../../shared/models/surveyGroup';
import AdminPage from '../hocs/AdminPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import ExportButton from '../ExportButton';
import ModelFormModal from '../modals/ModelFormModal';
import QuerySelector from '../QuerySelector';
import EditButton from '../EditButton';
import TenantLink from '../TenantLink';
import Field from '../Field';
import DeleteSurveyAnswersButton from '../DeleteSurveyAnswersButton';
import QueryDateRangeSelector from '../QueryDateRangeSelector';
import AnswerStatModal from '../modals/AnswerStatModal';

const { keys } = Object;
const db = firebase.firestore();
const settingsRef = db.collection('settings');
const surveyGroupsRef = db.collection('surveyGroups');

export default AdminPage(function AdminSurveyAnswers(props) {
  const { tenant, user, history, location, } = props;
  const now = new Date();
  const queryParams = useQueryParams();
  const {
    surveys: surveysForFilter,
    groups: groupsForFilter,
    picNames: picNamesForFilter,
    statuses: statusesForFilter,
    dateRange,
  } = queryParams;
  const [answerStatQuestionId, setAnswerStatQuestionId] = useState();
  const [showsAnswerStat, toggleAnswerStat] = useToggle();
  const startOn = dateRange?.[0] ? new Date(dateRange[0]) : startOfMonth(new Date());
  const endOn = dateRange?.[1] ? new Date(dateRange[1]) : endOfMonth(new Date());
  const userVoices = sortBy(useCollectionSubscriptionInTenant(db.collection('userVoices')), _ => _.createdAt.toDate());
  const userVoicesById = keyBy(userVoices, 'id');
  const _surveys = useCollectionSubscriptionInTenant(db.collection('surveys'));
  const surveys = useMemo(_ => orderBy(_surveys, (_) => _.createdAt.toDate(), 'desc'), [_surveys]);
  const maxStepsCount = Math.max(...surveys.map(_ => _.steps?.length || 1), 1);
  const stepOptions = Array(maxStepsCount).fill().map((_, i) => ({ label: `ステップ${i + 1}`, value: i.toString() }));
  const areaSetting = useDocumentSubscription(db.collection('settings').doc([tenant.id, 'area'].join('__')));
  const surveysById = useMemo(_ => keyBy(surveys, 'id'), [surveys]);
  const surveyOptions = sortBy(surveys, _ => !!_.isHidden)
    .filter(_ => isEmpty(groupsForFilter) || groupsForFilter.includes(_.surveyGroupId))
    .map((_) => ({
      label: _.isHidden ? (
        <div className="text-muted">
          [非表示] {_.name}
        </div>
      ) : _.name,
      value: _.id,
    }));
  const surveyAnswers = useCollectionSubscriptionInTenant(startOn && endOn && db.collection('surveyAnswers').where('createdAt', '>=', startOfDay(startOn)).where('createdAt', '<=', endOfDay(endOn)).orderBy('createdAt', 'desc'), [startOn?.toString(), endOn?.toString()]);
  const _questions = useCollectionSubscriptionInTenant(db.collection('questions'));
  const questions = useMemo(_ => sortBy(_questions), [_questions]);
  const rows = useMemo(_ => surveyAnswers.map((surveyAnswer) => {
    const survey = surveysById[surveyAnswer.surveyId];
    const area = areaFromPostalCode(surveyAnswer.createdBy.postalCode || '', areaSetting);
    const picName = surveyAnswer.respondedBy?.displayName || area?.user.displayName;
    let filteredQuestionRows = survey?.questionRows;
    return {
      ...surveyAnswer,
      survey,
      area,
      picName,
    };
  }), [surveyAnswers, surveysById, areaSetting]);

  // NOTE: filter
  const filteredRows = useMemo(_ => {
    let filteredRows = rows;
    if (!isEmpty(surveysForFilter)) {
      filteredRows = filteredRows.filter((_) => surveysForFilter.includes(_.surveyId));
    }
    if (!isEmpty(groupsForFilter)) {
      filteredRows = filteredRows.filter((_) => groupsForFilter.includes(get(surveysById[_.surveyId], 'surveyGroupId')));
    }
    if (!isEmpty(picNamesForFilter)) {
      filteredRows = filteredRows.filter((_) => picNamesForFilter?.includes(_.picName));
    }
    if (!isEmpty(statusesForFilter)) {
      filteredRows = filteredRows.filter((_) => statusesForFilter?.includes(_.status));
    }
    return filteredRows;
  }, [rows, surveysById, queryParams]);

  const answeredSurveys = uniq(filteredRows.map(_ => _.surveyId)).map(_ => surveysById[_]);
  const answeredIds = filteredRows.map(_ => Object.keys(_.answers | {})).flat()
  const relatedQuestionIds = uniq([
    ...answeredSurveys.flatMap((survey) => {
      return (
        !isEmpty(queryParams.stepIndices) ? (
          survey?.questionRows?.filter(_ => queryParams.stepIndices.includes((_.stepIndex ?? 0).toString()))
        ) : survey?.questionRows
      )?.map(_ => _.questionId) || [];
    }),
    ...answeredIds,
  ].flat());
  const relatedQuestions = questions.filter(_ => relatedQuestionIds.includes(_.id));
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  const _surveyGroups = useCollectionSubscriptionInTenant(surveyGroupsRef);
  const surveyGroups = [...defaultGroups, ..._surveyGroups];
  const groupOptions = surveyGroups.map((_) => ({ label: _.name, value: _.id }));

  const picNameOptions = uniq(rows.map((_) => _.picName)).filter((_) => _).map((_) => ({ label: _, value: _ }));
  const statusOptions = Object.entries(statuses).map(([value, { label }]) => ({ label, value }));

  const rowsForExport = () => {
    return filteredRows.map((row) => {
      const { id, survey, answers, tag, createdAt, createdBy, adminNote, adminNoteUpdatedAt, adminNoteUpdatedBy } = row;
      return {
        surveyTitle: survey?.title,
        createdAt: formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        createdBy: createdBy?.displayName,
        userEmail: createdBy?.email,
        tag,
        adminNote,
        adminNoteUpdatedAt: adminNoteUpdatedAt?.toDate().toLocaleString(),
        adminNoteUpdatedBy: adminNoteUpdatedBy?.displayName,
        ...(
          relatedQuestions.reduce((x, question) => {
            const { id, name, type, } = question;
            const answer = answers[id];
            const value = ({
              checkbox: keys(answer || {}),
              imageFile: [answer].flat().map(_ => _?.url).join('\n'),
            })[type] || answer;
            return {
              ...x,
              [name]: value,
            };
          }, {})
        ),
      };
    });
  };
  const onClickDateButton = ([startOn, endOn]) => {
    const path = fullPathWithParams(
      { dateRange: [formatDate(startOn, 'yyyy-MM-dd'), formatDate(endOn, 'yyyy-MM-dd')] },
      location
    );
    history.replace(encodeURI(path));
  };
  const openAnswerStatModal = (questionId) => {
    setAnswerStatQuestionId(questionId);
    toggleAnswerStat(true);
  };

  return (
    <div>
      <div className="admin-survey-answers container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>アンケート回答一覧</h4>
        </div>
        <div className="d-flex flex-wrap gap-1 align-items-end mb-2" style={{ position: "relative" , zIndex: 3 }}>
          <Button onClick={onClickDateButton.bind(null, [addDays(now, -1), addDays(now, -1)])}>昨日</Button>
          <Button onClick={onClickDateButton.bind(null, [now, now])}>今日</Button>
          <Button onClick={onClickDateButton.bind(null, [startOfMonth(now), endOfMonth(now)])}>今月</Button>
          <Button
            onClick={onClickDateButton.bind(null, [startOfMonth(addMonths(now, -1)), endOfMonth(addMonths(now, -1))])}
          >
            先月
          </Button>
          <QueryDateRangeSelector label="期間" defaultValue={[startOn, endOn]} paramName="dateRange" pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }} />
          <div className="d-flex flex-wrap align-items-end gap-2">
            <QuerySelector
              paramName='groups'
              className='ml-0'
              width={400}
              isMulti
              options={groupOptions}
              label='グループで絞込み'
            />
            <QuerySelector
              paramName="surveys"
              width={400}
              isMulti
              options={surveyOptions}
              label="アンケートページで絞込み"
            />
            <QuerySelector
              paramName="stepIndices"
              width={400}
              isMulti
              options={stepOptions}
              label="ステップで絞込み"
            />
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2 mb-3 align-items-end" style={{ position: "relative" , zIndex: 2 }}>
          <QuerySelector paramName="picNames" width={250} isMulti options={picNameOptions} label="担当で絞込み" />
          <QuerySelector paramName="statuses" width={250} isMulti options={statusOptions} label="状態で絞込み" />
        </div>
        <div className="d-flex justify-content-end mb-3">
          <DeleteSurveyAnswersButton targetAnswers={checkedAnswers} onFinish={() => setCheckedAnswers([])} />
          <ExportButton className='ml-2' fileName='アンケート回答.csv' rows={rowsForExport} hasPersonalInfo user={user} />
        </div>
        <div className="overflow-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
          {rows.length > 0 ? (
            <table className="table table-bordered">
              <thead className="thead-light text-center position-sticky" style={{ top: 0, zIndex: 1, }}>
                <tr>
                  <th>削除</th>
                  <th style={{ minWidth: 80 }}>状態</th>
                  <th style={{ minWidth: 200 }}>グループ</th>
                  <th style={{ minWidth: 200 }}>アンケートページ</th>
                  <th style={{ minWidth: 120 }}>回答日時</th>
                  <th style={{ minWidth: 120 }}>回答者</th>
                  <th style={{ minWidth: 120 }}>タグ</th>
                  <th style={{ minWidth: 120 }}>エリア担当</th>
                  <th style={{ minWidth: 350 }}>メモ</th>
                  <th style={{ minWidth: 200 }}>ユーザーズボイス</th>
                  {relatedQuestions.map((question) => {
                    const { id, name } = question;
                    return (
                      <th key={id} className="position-relative" style={{ minWidth: 200, maxWidth: 300 }}>
                        {name}
                        <Button color="link" size="sm" className="position-absolute" style={{ top: 2, right: 2 }} onClick={openAnswerStatModal.bind(null, question.id)}>
                          <span className="fas fa-search" />
                        </Button>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {filteredRows.map((row) => {
                  const { id, ref, survey, answers, tag, adminNote, adminNoteUpdatedAt, adminNoteUpdatedBy, userVoiceIds, userVoiceHeader, publicQuestionIds, picName, createdAt, createdBy, status, } = row;
                  const handleCheck = ({ target: { checked }}) => {
                    if (checked) {
                      setCheckedAnswers(array => [...array, id]);
                    } else {
                      setCheckedAnswers(array => array.filter(_ => _ !== id));
                    }
                  }
                  const { label: statusLabel, color } = statuses[status] || {};

                  return (
                    <tr key={id}>
                      <td>{['staff', 'admin'].includes(createdBy.role) && <input type="checkbox" onChange={handleCheck} />}</td>
                      <td>
                        <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
                      </td>
                      <td>{surveyGroups.find(_ => _.id === survey?.surveyGroupId)?.name}</td>
                      <td>{survey?.title}</td>
                      <td>
                        <TenantLink to={`/admin/surveyAnswers/${id}`}>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</TenantLink>
                      </td>
                      <td>
                        <TenantLink to={`/admin/users/${createdBy?.uid}`}>{createdBy?.displayName}</TenantLink>
                      </td>
                      <td>{tag}</td>
                      <td>
                        {picName}
                      </td>
                      <td style={{ whiteSpace: 'pre-line' }}>
                        <div>
                          <EditButton
                            label={false}
                            color="link"
                            size="sm"
                            itemRef={ref}
                            FormModal={ModelFormModal}
                            formProps={{ title: 'メモ', fields: pick(adminFields(), ['adminNote']), }}
                            beforeSubmit={(values) => ({ ...values, adminNoteUpdatedAt: new Date(),adminNoteUpdatedBy: auditData(user) })}
                          />
                        </div>
                        {adminNote}<br />
                        {adminNoteUpdatedAt && adminNoteUpdatedBy && <small className='text-muted'>{adminNoteUpdatedAt.toDate().toLocaleString()} {adminNoteUpdatedBy.displayName}</small>}
                      </td>
                      <td style={{ whiteSpace: 'pre-line' }}>
                        <div>
                          <EditButton
                            label={false}
                            color="link"
                            size="sm"
                            itemRef={ref}
                            FormModal={ModelFormModal}
                            formProps={{ title: 'ユーザーズボイス', fields: pick(adminFields({ userVoices }), ['userVoiceIds', 'userVoiceHeader']), }}
                          />
                        </div>
                        <div>
                          {userVoiceIds?.map(_ => userVoicesById[_]?.name).filter(_ => _).map(_ => <span className="badge badge-secondary">{_}</span>)}
                        </div>
                        <div className="border p-1 rounded small" style={{ whiteSpace: 'pre-line' }}>
                          {userVoiceHeader}
                        </div>
                      </td>
                      {relatedQuestions.map((question) => {
                        const { id, type } = question;
                        const answer = answers[id];
                        const displayValue = {
                          text: answer,
                          checkbox: keys(answer || {}).join(', '),
                          radio: answer,
                          select: answer,
                          imageFile: [answer].flat().map((answer, i) => (
                            <div key={i}>
                              <a href={answer?.url} target="_blank">
                                {answer?.name}
                              </a>
                            </div>
                          )),
                        }[type];
                        return (
                          <td key={id}>
                            {
                              !isEmpty(userVoiceIds) && keys(answers || {}).includes(id) && (
                                <div>
                                  <Field type="boolean" label={<span className="small d-block">公開</span>} value={publicQuestionIds?.includes(id)} setValue={_ => ref.update({ publicQuestionIds: publicQuestionIds?.includes(id) ? (publicQuestionIds || []).filter(_ => _ !== id) : [...(publicQuestionIds || []), id] })} />
                                </div>
                              )
                            }
                            <div>
                              {displayValue}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
      {showsAnswerStat && <AnswerStatModal isOpen onClickClose={toggleAnswerStat.bind(null, false)} defaultQuestionId={answerStatQuestionId} questions={questions} rows={filteredRows} />}
    </div>
  );
});
