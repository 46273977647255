import React, { useEffect, } from 'react';
import { mapValues, omit, isEmpty, sumBy, get, keyBy } from 'lodash';
import { Button, FormGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import qs from 'qs';
import numeral from 'numeral';
import classnames from 'classnames';
import { useToggle, useAsync, useCounter, } from 'react-use';

import firebase, { functions } from '../../firebase';
import TenantPage from '../hocs/TenantPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import { ordererFields, destinationFields, payerFields, } from '../../shared/models/order';
import { fieldDisplayValue } from '../../shared/util';
import OrderFlowSection from '../OrderFlowSection';
import Field from '../Field';
import AppButton from '../AppButton';
import SettingText from '../SettingText';
import AppCardElement from '../AppCardElement';
import RichTextContent from '../RichTextContent';
import useFormState from '../hooks/useFormState';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import env from '../../env';
import StripeForm from '../forms/StripeForm';
import { stripePromise } from '../../stripe';

const { entries } = Object;
const db = firebase.firestore();
const getWishOrder = functions.httpsCallable('getWishOrder');
const payWishOrder = functions.httpsCallable('payWishOrder');
const updateInventoriesByOrder = functions.httpsCallable('updateInventoriesByOrder');
const createPaymentIntent = functions.httpsCallable('createPaymentIntent');

export default TenantPage(function WishOrderPayment(props) {
  const { tenant, } = props;
  console.log(props, tenant);
  const [version, { inc: updateVersion, }] = useCounter();
  const [screenVersion, { inc: updateScreenVersion }] = useCounter();
  const queryParams = useQueryParams();
  const { value: { order: wishOrder, isEnoughInventories, } = {}, loading: isLoading, } = useAsync(async () => {
    const { data } = await getWishOrder({ token: queryParams.token });
    return data;
  }, [queryParams.token, screenVersion]);
  const chargeOrPaymentIntent = wishOrder?.charge ?? wishOrder?.paymentIntent;
  const statedFields = useFormState({}, payerFields(), false);
  const cartSetting = useDocumentSubscription(db.collection('settings').doc([tenant?.id, 'cart'].join('__')));
  const products = useDocumentsFetch(wishOrder?.orderItems.map(_ => db.collection('products').doc(_.productId)), [wishOrder]);
  const productsById = keyBy(products, 'id');
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid);
  const { value: paymentIntent, loading } = useAsync(async () => {
    return (wishOrder != null && await createPaymentIntent({ amount: wishOrder.amount - wishOrder.discountAmount + wishOrder.shipmentFee, })).data;
  }, [wishOrder != null, version]);
  console.log(3, paymentIntent, loading);
  const onSubmit = async (stripe, elements) => {
    if (isUnsubmittable || isSubmitting) return;

    toggleSubmitting(true);
    try {
      const values = mapValues(statedFields, 'value');
      const { data: updatedOrderItems = [] } = await updateInventoriesByOrder({ tenantId: tenant?.id, orderItems: wishOrder?.orderItems, });
      const outOrderItems = updatedOrderItems.filter((_) => _.isOut);
      if (outOrderItems.length > 0) {
        const outProducts = outOrderItems.map((_) => productsById[_.productId]);
        toast.error(
          <div>
            <div>
              申し訳ございません。
              <br />
              たった今、以下の商品が在庫切れとなりました。
            </div>
            <ul className="mt-2">
              {outProducts.map((_) => (
                <li>{_.name}</li>
              ))}
            </ul>
          </div>,
          { autoClose: 10000 }
        );
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: {
                country: "JP"
              }
            }
          }
        },
      });
      if(error) throw error;

      const { data } = await payWishOrder({
        wishOrderId: wishOrder.id,
        stripePaymentIntentId: paymentIntent?.id,
        values,
      });
      if (get(data, 'error') != null) throw new Error(data.error.message);
      window.scrollTo(0, 0);
      updateScreenVersion();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };
  const [isSubmitting, toggleSubmitting] = useToggle();
  const { orderItems, } = wishOrder || {};

  return (
    <div className="wish-order-payment h-100 container">
      <div className="py-5">
        <h4 className="mt-4 h5 text-center font-weight-bold">おねだり注文 決済フォーム</h4>
        {
          isLoading ? (
            <div className="py-3">
              <span className="fas fa-spin fa-spinner mr-1" />
              Loading...
            </div>
          ) : wishOrder && paymentIntent?.client_secret != null && (
            <div>
              {
                !isEnoughInventories && chargeOrPaymentIntent == null && (
                  <div className="mt-5 alert alert-danger">
                    現在、ご注文の商品の在庫が不足しています
                  </div>
                )
              }
              <Elements stripe={stripePromise} options={{ clientSecret: paymentIntent?.client_secret, }}>
                <StripeForm onSubmit={onSubmit}>
                  <section className="container mt-5">
                    {
                      chargeOrPaymentIntent != null && (
                        <div className='text-break border border-info rounded p-3 mt-3'>
                          <RichTextContent html={cartSetting.wishThanksHeader} />
                        </div>
                      )
                    }
                    <div className="rounded bg-light-grey p-3 mt-3">
                      {
                        wishOrder.wishFile != null && (
                          wishOrder.wishFile.type.startsWith('video') ? (
                            <video src={wishOrder.wishFile.url} width="100%" controls controlsList="nodownload" />
                          ) : (
                            <img src={wishOrder.wishFile.url} width="100%" />
                          )
                        )
                      }
                      <div className="mt-2" style={{ whiteSpace: 'pre-line', }}>
                        {wishOrder.wishMessage}
                      </div>
                    </div>
                    <div>
                      <div className="mt-5">
                        <h4>注文番号</h4>
                        {wishOrder.id}
                      </div>
                      <div className="mt-5">
                        <h4>注文内容</h4>
                        {
                          orderItems.map((orderItem, i) => {
                            const product = productsById[orderItem.productId];
                            return (
                              <div key={i} className="d-flex gap-2">
                                <div>
                                  {product?.name}
                                </div>
                                <div>
                                  {orderItem.quantity}個
                                </div>
                              </div>
                            );
                          })
                        }
                        <div className="mt-3">
                          お支払金額: {numeral(wishOrder.amount - wishOrder.discountAmount + wishOrder.shipmentFee).format()}円
                        </div>
                      </div>
                      <div className="mt-5">
                        <h4>注文者情報</h4>
                        <table className="table table-bordered">
                          <tbody>
                            {entries(ordererFields()).map(([fieldName, fieldSettings]) => {
                              const { label } = fieldSettings;
                              const value = fieldDisplayValue(wishOrder[fieldName], fieldSettings);
                              return (
                                <tr>
                                  <th>{label}</th>
                                  <td>{value}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="mt-5">
                        <h4>お届け先情報</h4>
                        <table className="table table-bordered">
                          <tbody>
                            {entries(destinationFields()).map(([fieldName, fieldSettings]) => {
                              const { label } = fieldSettings;
                              const value = fieldDisplayValue(wishOrder[fieldName], fieldSettings);
                              return (
                                <tr>
                                  <th>{label}</th>
                                  <td>{value}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {
                        chargeOrPaymentIntent != null && (
                          <div className="mt-5">
                            <h4>決済者情報</h4>
                            <table className="table table-bordered">
                              <tbody>
                                {entries(payerFields()).map(([fieldName, fieldSettings]) => {
                                  const { label } = fieldSettings;
                                  const value = fieldDisplayValue(wishOrder[fieldName], fieldSettings);
                                  return (
                                    <tr>
                                      <th>{label}</th>
                                      <td>{value}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )
                      }
                      {
                        isEnoughInventories && chargeOrPaymentIntent == null && (
                          <div className="mt-5">
                            <h4>お支払い情報</h4>
                            {
                              entries(statedFields).map(([fieldName, fieldSetting]) => (
                                <Field
                                  key={fieldName}
                                  name={fieldName}
                                  values={mapValues(statedFields, 'value')}
                                  documentName="survey"
                                  {...fieldSetting}
                                />
                              ))
                            }
                            <FormGroup className="mt-3">
                              <Label>
                                クレジットカード
                                <span className="text-danger small">【必須】</span>
                              </Label>
                              {
                                paymentIntent?.client_secret && (
                                  <PaymentElement
                                    options={{
                                      fields: {
                                        billingDetails: {
                                          address: {
                                            country: "never"
                                          }
                                        }
                                      }
                                    }}
                                  />
                                )
                              }
                              <small className="form-text text-muted">
                                CVCはセキュリティコードです。署名欄の３桁など、カード会社所定のコードを入力して下さい。
                              </small>
                            </FormGroup>
                          </div>
                        )
                      }
                    </div>
                    {
                      isEnoughInventories && chargeOrPaymentIntent == null && (
                        <div className="d-flex mt-5">
                          <AppButton
                            size="lg"
                            color="primary"
                            className="save flex-fill ml-2"
                            type="submit"
                            color="primary"
                            disabled={isSubmitting || isUnsubmittable}
                          >
                            <span
                              className={classnames('fas mr-1', {
                                'fa-arrow-right': !isSubmitting,
                                'fa-spin fa-spinner': isSubmitting,
                              })}
                            />
                              決済する
                          </AppButton>
                        </div>
                      )
                    }
                  </section>
                </StripeForm>
              </Elements>
            </div>
          )
        }
      </div>
    </div>
  );
});
