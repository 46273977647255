import { Form, } from 'reactstrap';
import { useStripe, useElements } from '@stripe/react-stripe-js';

export default function StripeForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const onSubmit = async (event) => {
    event.preventDefault();
    await props.onSubmit(stripe, elements);
  };
  return (
    <Form onSubmit={onSubmit}>
      {props.children}
    </Form>
  );
}

